@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes fadeInOut {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2); } }

@-webkit-keyframes zoomInAndFadeOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0; } }

@keyframes zoomInAndFadeOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0; } }

/**
* Button
**/
.ant-btn {
  border-color: #60baeb;
  border-radius: 0.25rem;
  color: #60baeb;
  text-shadow: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .ant-btn:focus, .ant-btn:hover {
    background: #60baeb;
    border-color: #60baeb;
    color: #ffffff; }
  .ant-btn.ant-btn-primary {
    background: #60baeb;
    border-color: #60baeb;
    color: #ffffff; }
    .ant-btn.ant-btn-primary:hover {
      background: #1c9ce2;
      border-color: #1c9ce2; }

/**
* Checkbox
**/
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #60baeb; }

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #60baeb; }

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #60baeb; }

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #60baeb; }

.ant-checkbox-checked:after {
  border: 1px solid #60baeb; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #60baeb;
  border-color: #60baeb; }

/**
* Input
**/
.ant-slider + .ant-input,
.ant-slider + .ant-input-number {
  margin-left: 0.5rem; }

.ant-input {
  width: auto; }
  .ant-input:focus, .ant-input:hover, .ant-input.ant-input-focused {
    border-color: #60baeb;
    box-shadow: none; }

.ant-input-number {
  width: 65px; }
  .ant-input-number:focus, .ant-input-number:hover, .ant-input-number.ant-input-number-focused {
    border-color: #60baeb;
    box-shadow: none; }
  .ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: #60baeb; }

/**
* Menu
**/
.ant-menu {
  background: transparent; }
  .ant-menu .ant-menu-item:hover, .ant-menu .ant-menu-item.ant-menu-item-selected {
    color: #60baeb; }
  .ant-menu .ant-menu-item.ant-menu-item-selected {
    background-color: white; }
  .ant-menu .ant-menu-item:after {
    border-color: #60baeb; }
  .ant-menu .ant-menu-submenu-arrow:before, .ant-menu .ant-menu-submenu-arrow:after {
    background: #adb5bd; }
  .ant-menu .ant-menu-submenu-selected {
    border-color: #60baeb;
    color: #60baeb; }
  .ant-menu .ant-menu-item:hover, .ant-menu .ant-menu-item.ant-menu-item-selected,
  .ant-menu .ant-menu-submenu-title:hover,
  .ant-menu .ant-menu-submenu-title.ant-menu-item-selected {
    border-color: #60baeb;
    color: #60baeb; }
    .ant-menu .ant-menu-item:hover .ant-menu-submenu-arrow:before, .ant-menu .ant-menu-item:hover .ant-menu-submenu-arrow:after, .ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-submenu-arrow:before, .ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-submenu-arrow:after,
    .ant-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
    .ant-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
    .ant-menu .ant-menu-submenu-title.ant-menu-item-selected .ant-menu-submenu-arrow:before,
    .ant-menu .ant-menu-submenu-title.ant-menu-item-selected .ant-menu-submenu-arrow:after {
      background: #60baeb; }

/**
* Message
**/
.ant-message {
  z-index: 99999; }
  .ant-message .ant-message-notice {
    padding: 0 1rem 0 0 !important;
    text-align: right;
    visibility: hidden; }
    .ant-message .ant-message-notice .ant-message-notice-content {
      border-radius: 0.3rem;
      overflow: hidden;
      position: relative;
      visibility: visible; }
      .ant-message .ant-message-notice .ant-message-notice-content .ant-message-custom-content {
        display: flex;
        align-items: center; }
  .ant-message .anticon {
    margin-right: 1rem; }
  .ant-message ul {
    margin: 0;
    padding: 0;
    text-align: left; }
    .ant-message ul li {
      list-style: none; }

/**
* Notifications
**/
.ant-notification .ant-notification-notice-description .modal-footer {
  border: none;
  padding: 1.25rem 0 0; }
  .ant-notification .ant-notification-notice-description .modal-footer .btn-link {
    text-transform: uppercase; }

/**
* Popover
**/
.ant-popover-inner-content {
  max-height: 100vh;
  overflow: auto; }
  .ant-popover-inner-content [contenteditable] {
    display: block;
    padding: 0.25rem 0.5rem;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    min-width: 50px;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .ant-popover-inner-content [contenteditable]:focus {
      border-color: #60baeb;
      box-shadow: 0 0 0 4px rgba(96, 186, 235, 0.12);
      outline: none; }

/**
* Progress
**/
.ant-progress .ant-progress-bg {
  background: #60baeb; }

.ant-progress .ant-progress-circle-path {
  stroke: linear-gradient(150deg, #365c6c 0%, rgba(96, 186, 235, 0.8) 100%); }

.ant-message .ant-progress {
  align-items: center;
  display: flex;
  position: absolute;
  height: 22px;
  width: calc(100% - 2rem); }
  .ant-message .ant-progress.ant-progress-status-success .ant-progress-bg {
    background: #52c41a; }
  .ant-message .ant-progress .ant-progress-inner {
    border-radius: 0;
    height: 3px;
    left: -16px;
    position: absolute;
    top: -10px;
    width: calc(100% + 32px); }
    .ant-message .ant-progress .ant-progress-inner .ant-progress-bg,
    .ant-message .ant-progress .ant-progress-inner .ant-progress-bg:before {
      border-radius: 0; }
  .ant-message .ant-progress .ant-progress-text {
    display: flex;
    justify-content: center; }
    .ant-message .ant-progress .ant-progress-text .anticon {
      margin: 0; }

/**
* Select
**/
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ced4da;
  border-radius: 0.25rem; }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ced4da; }

.ant-select:not(.ant-select-disabled).ant-select-focused .ant-select-selector {
  border-color: #60baeb !important;
  box-shadow: 0 0 0 4px rgba(96, 186, 235, 0.12) !important; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: white; }

/**
* Slider
**/
.ant-slider {
  flex-grow: 1; }
  .ant-slider .ant-slider-rail {
    background-color: #dee2e6; }
  .ant-slider:hover .ant-slider-rail {
    background-color: #ced4da; }
  .ant-slider .ant-slider-track {
    background-color: #60baeb !important; }
  .ant-slider .ant-slider-handle {
    border: 2px solid #60baeb !important; }
    .ant-slider .ant-slider-handle:focus {
      box-shadow: 0 0 0 5px rgba(96, 186, 235, 0.12) !important; }

/**
* Tabs
**/
.ant-tabs {
  flex-direction: column;
  flex-grow: 1;
  display: flex; }
  .ant-tabs.ant-tabs-card .ant-tabs-nav {
    margin: 0;
    -webkit-transition: none;
    transition: none; }
    .ant-tabs.ant-tabs-card .ant-tabs-nav:before {
      display: none; }
    .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
      cursor: default;
      background: transparent;
      border: none;
      margin: 0 !important;
      padding: 0;
      position: unset; }
      .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div {
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 0.5rem 0.5rem 0 0;
        color: #212529 !important;
        display: flex;
        font-weight: normal;
        height: 36px;
        margin: 4px 0 0 !important;
        padding: 0.5rem 1rem;
        position: relative;
        -webkit-transition: all 0.3s ease !important;
        transition: all 0.3s ease !important; }
        .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:hover {
          box-shadow: -8px 0 0 -7px transparent;
          color: #212529;
          z-index: 1; }
          .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:hover:before, .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:hover:after {
            color: #212529; }
        .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div [type='close'] {
          align-items: center;
          border-radius: 2rem;
          color: #212529;
          cursor: pointer;
          display: flex;
          font-size: 1.2rem;
          height: 32px;
          justify-content: center;
          margin: 0 -0.75rem 0 0.5rem;
          -webkit-transition: background 0.3s ease;
          transition: background 0.3s ease;
          width: 32px; }
        .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div.active {
          background: #212529;
          box-shadow: -8px 0 0 -7px transparent;
          color: #212529;
          -webkit-transition: none !important;
          transition: none !important;
          z-index: 2; }
          .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div.active:before, .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div.active:after {
            color: #212529;
            -webkit-transition: none !important;
            transition: none !important; }
        .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:before, .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:after {
          background: transparent;
          bottom: 0;
          color: transparent;
          content: '';
          width: 1rem;
          height: 1rem;
          position: absolute;
          border-radius: 0.75rem;
          -webkit-transition: all 0.3s ease !important;
          transition: all 0.3s ease !important; }
        .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:before {
          box-shadow: 0.5rem 0.5rem 0 0 currentColor;
          right: 100%; }
        .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn > div:after {
          box-shadow: -0.5rem 0.5rem 0 0 currentColor;
          left: 100%; }
  .ant-tabs.ant-tabs-card .ant-tabs-content-holder {
    flex-direction: column;
    flex-grow: 1;
    display: flex; }
    .ant-tabs.ant-tabs-card .ant-tabs-content-holder .ant-tabs-content {
      flex-grow: 1; }
  .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav {
    margin: 0; }
    .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav:before {
      display: none; }
    .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-nav-list {
      display: flex;
      flex-grow: 1; }
      .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
        background: #60baeb; }
      .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
        flex-grow: 1;
        font-size: 0.7rem;
        font-weight: normal;
        justify-content: center;
        line-height: 40px;
        margin: 0;
        padding: 0 0.5rem;
        text-align: center;
        text-transform: uppercase; }
        .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:hover .ant-tabs-tab-btn, .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #60baeb; }
    .ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-nav-more {
      padding: 0 0.5rem; }

/**
 * Border
 */
.rounded-bottom {
  border-radius: 0 0 0.25rem 0.25rem !important; }

/**
 * Box shadow
 */
.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05); }

/**
 * Form
 */
.form-group {
  position: relative; }

/**
 * Buttons
 */
button:focus {
  outline: none !important; }

.btn-group [type='radio'] {
  position: absolute;
  margin: 0;
  opacity: 0;
  z-index: -1; }

.btn-link {
  text-decoration: none !important; }

/**
 * Disabled
 */
[disabled],
.disabled {
  opacity: 0.65;
  cursor: default !important; }

/**
 * Dropdown
 */
.dropdown .dropdown-header {
  font-size: 0.8rem;
  color: #ced4da;
  text-transform: uppercase; }

/**
 * Tables
 */
thead th {
  color: #c1c3c6;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa !important; }

table span + .icon {
  position: absolute;
  margin-top: -3px; }

/**
 * Forms
 */
select.rounded-left {
  border-radius: 0.25rem 0 0 0.25rem !important; }

/**
 * Line height
 */
.line-height-0 {
  line-height: 0rem !important; }

.line-height-1 {
  line-height: 1rem !important; }

/**
 * Opacity
 */
.opacity-1 {
  opacity: 1 !important; }

/**
 * Cursor
 */
.cursor-default {
  cursor: default !important; }

/**
 * Modals
 */
.modal .modal-header {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex; }
  .modal .modal-header .modal-title {
    flex-grow: 1; }
    .modal .modal-header .modal-title img {
      width: 120px; }
  .modal .modal-header .close {
    text-shadow: none; }
    .modal .modal-header .close > span {
      display: flex;
      height: 24px;
      visibility: hidden;
      width: 24px; }
      .modal .modal-header .close > span:before {
        content: '\F0156';
        font: normal normal normal 24px/1 'Material Design Icons';
        visibility: visible; }

.modal .modal-footer {
  border: none; }
  .modal .modal-footer .btn {
    font-weight: 600;
    text-transform: uppercase; }

.modal.fade.show .modal-dialog.modal-bottom {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.modal.fade .modal-dialog.modal-bottom {
  bottom: 0;
  position: fixed;
  -webkit-transform: translate(0, 25%);
          transform: translate(0, 25%); }

.modal.fade .modal-dialog.modal-bottom, .modal.fade .modal-dialog.modal-top {
  width: 100%;
  max-width: 100%;
  margin: 0; }
  .modal.fade .modal-dialog.modal-bottom .modal-content, .modal.fade .modal-dialog.modal-top .modal-content {
    border: none;
    border-radius: 0; }
    .modal.fade .modal-dialog.modal-bottom .modal-content .modal-header,
    .modal.fade .modal-dialog.modal-bottom .modal-content .modal-footer, .modal.fade .modal-dialog.modal-top .modal-content .modal-header,
    .modal.fade .modal-dialog.modal-top .modal-content .modal-footer {
      padding: 1rem 2rem; }
    .modal.fade .modal-dialog.modal-bottom .modal-content .modal-body, .modal.fade .modal-dialog.modal-top .modal-content .modal-body {
      padding: 2rem 0; }

/**
 * Cutom input: checkbox, radio
 */
.custom-control .custom-radio > input {
  top: 50% !important;
  margin-top: -0.75rem;
  margin-left: -1.4rem; }

.custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
  top: 50% !important;
  margin-top: -8px; }

.custom-control .custom-control-label:empty:before, .custom-control .custom-control-label:empty:after {
  left: 0 !important;
  margin-left: 0 !important; }

.custom-control .custom-control-input {
  margin-left: 0;
  z-index: 1; }

.custom-control[data-some-checked='true'] .ant-checkbox {
  z-index: 0; }

/**
 * drag-enter
 */
.drag-enter:before {
  content: '\F0770' !important; }

/**
 * Focus
 */
.select__control.select__control--is-focused {
  color: #495057;
  background-color: #fff !important;
  border-color: #d1ebf9 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }

.slick-slider.quotes h5 {
  line-height: 1.5; }

.slick-slider.quotes h6 {
  opacity: 0.6; }

.slick-slider.quotes .slick-dots.avatars {
  position: relative;
  display: flex !important;
  justify-content: space-evenly;
  padding: 2rem 4rem 0;
  list-style: none;
  margin: 0; }
  .slick-slider.quotes .slick-dots.avatars li {
    width: 32px;
    height: 32px;
    opacity: 0.6;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .slick-slider.quotes .slick-dots.avatars li img {
      border-radius: 10rem;
      width: 100%; }
    .slick-slider.quotes .slick-dots.avatars li:hover, .slick-slider.quotes .slick-dots.avatars li.slick-active {
      opacity: 1;
      -webkit-transform: scale(1.3);
              transform: scale(1.3); }

/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #722ed1;
  --pink: #e83e8c;
  --red: #ff4d4f;
  --orange: #fa8c16;
  --yellow: #fadb14;
  --green: #52c41a;
  --teal: #20c997;
  --cyan: #13c2c2;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #60baeb;
  --secondary: #365c6c;
  --success: #52c41a;
  --info: #13c2c2;
  --warning: #fce359;
  --danger: #ff4d4f;
  --light: #f8f9fa;
  --dark: #343a40;
  --white: #fff;
  --gray: #ced4da;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #60baeb;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1c9ce2;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2ecf9; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #acdbf5; }

.table-hover .table-primary:hover {
  background-color: #bce3f6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bce3f6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c7d1d6; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #96aab3; }

.table-hover .table-secondary:hover {
  background-color: #b8c5cb; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b8c5cb; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cfeebf; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a5e088; }

.table-hover .table-success:hover {
  background-color: #c0e9ab; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c0e9ab; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bdeeee; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #84dfdf; }

.table-hover .table-info:hover {
  background-color: #a9e9e9; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a9e9e9; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef7d1; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdf0a9; }

.table-hover .table-warning:hover {
  background-color: #fdf3b8; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fdf3b8; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffcdce; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa2a3; }

.table-hover .table-danger:hover {
  background-color: #ffb4b5; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffb4b5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #f1f3f5; }

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #e6e9ec; }

.table-hover .table-gray:hover {
  background-color: #e2e6ea; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #e2e6ea; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #d1ebf9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #52c41a; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(82, 196, 26, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #52c41a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2352c41a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #52c41a;
    box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #52c41a;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2352c41a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #52c41a;
    box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #52c41a; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #52c41a; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #52c41a; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #6ae32e;
  background-color: #6ae32e; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #52c41a; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #52c41a; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #52c41a;
  box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff4d4f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 77, 79, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff4d4f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff4d4f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4d4f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ff4d4f;
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ff4d4f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff4d4f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4d4f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ff4d4f;
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff4d4f; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff4d4f; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ff4d4f; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff8081;
  background-color: #ff8081; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff4d4f; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff4d4f; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #60baeb;
  border-color: #60baeb; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3eabe7;
    border-color: #33a6e5; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #3eabe7;
    border-color: #33a6e5;
    box-shadow: 0 0 0 0.2rem rgba(120, 196, 238, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #60baeb;
    border-color: #60baeb; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #33a6e5;
    border-color: #27a1e4; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 196, 238, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #365c6c;
  border-color: #365c6c; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #294653;
    border-color: #253f4a; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #294653;
    border-color: #253f4a;
    box-shadow: 0 0 0 0.2rem rgba(84, 116, 130, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #365c6c;
    border-color: #365c6c; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #253f4a;
    border-color: #213842; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(84, 116, 130, 0.5); }

.btn-success {
  color: #fff;
  background-color: #52c41a;
  border-color: #52c41a; }
  .btn-success:hover {
    color: #fff;
    background-color: #44a216;
    border-color: #3f9714; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #44a216;
    border-color: #3f9714;
    box-shadow: 0 0 0 0.2rem rgba(108, 205, 60, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #52c41a;
    border-color: #52c41a; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3f9714;
    border-color: #3a8c13; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 205, 60, 0.5); }

.btn-info {
  color: #fff;
  background-color: #13c2c2;
  border-color: #13c2c2; }
  .btn-info:hover {
    color: #fff;
    background-color: #109f9f;
    border-color: #0e9494; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #109f9f;
    border-color: #0e9494;
    box-shadow: 0 0 0 0.2rem rgba(54, 203, 203, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #13c2c2;
    border-color: #13c2c2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0e9494;
    border-color: #0d8888; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 203, 203, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #fce359;
  border-color: #fce359; }
  .btn-warning:hover {
    color: #212529;
    background-color: #fbdd33;
    border-color: #fbdb27; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #fbdd33;
    border-color: #fbdb27;
    box-shadow: 0 0 0 0.2rem rgba(219, 199, 82, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fce359;
    border-color: #fce359; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #fbdb27;
    border-color: #fbd81a; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(219, 199, 82, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff2729;
    border-color: #ff1a1d; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ff2729;
    border-color: #ff1a1d;
    box-shadow: 0 0 0 0.2rem rgba(255, 104, 105, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff1a1d;
    border-color: #ff0d10; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 104, 105, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-gray {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da; }
  .btn-gray:hover {
    color: #212529;
    background-color: #b8c1ca;
    border-color: #b1bbc4; }
  .btn-gray:focus, .btn-gray.focus {
    color: #212529;
    background-color: #b8c1ca;
    border-color: #b1bbc4;
    box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #212529;
    background-color: #b1bbc4;
    border-color: #aab4bf; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }

.btn-outline-primary {
  color: #60baeb;
  border-color: #60baeb; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #60baeb;
    border-color: #60baeb; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #60baeb;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #60baeb;
    border-color: #60baeb; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.5); }

.btn-outline-secondary {
  color: #365c6c;
  border-color: #365c6c; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #365c6c;
    border-color: #365c6c; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 92, 108, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #365c6c;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #365c6c;
    border-color: #365c6c; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 92, 108, 0.5); }

.btn-outline-success {
  color: #52c41a;
  border-color: #52c41a; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #52c41a;
    border-color: #52c41a; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #52c41a;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #52c41a;
    border-color: #52c41a; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.5); }

.btn-outline-info {
  color: #13c2c2;
  border-color: #13c2c2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #13c2c2;
    border-color: #13c2c2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 194, 194, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #13c2c2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #13c2c2;
    border-color: #13c2c2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 194, 194, 0.5); }

.btn-outline-warning {
  color: #fce359;
  border-color: #fce359; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fce359;
    border-color: #fce359; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 227, 89, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fce359;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fce359;
    border-color: #fce359; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 227, 89, 0.5); }

.btn-outline-danger {
  color: #ff4d4f;
  border-color: #ff4d4f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff4d4f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-gray {
  color: #ced4da;
  border-color: #ced4da; }
  .btn-outline-gray:hover {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #ced4da;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }

.btn-link {
  font-weight: 400;
  color: #60baeb;
  text-decoration: none; }
  .btn-link:hover {
    color: #1c9ce2;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #60baeb; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #60baeb;
    background-color: #60baeb; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #d1ebf9; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #60baeb;
  background-color: #60baeb; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(96, 186, 235, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(96, 186, 235, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(96, 186, 235, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(96, 186, 235, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #d1ebf9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #d1ebf9;
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #60baeb;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #60baeb;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #60baeb;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #60baeb; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #60baeb;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1c9ce2;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #60baeb;
  border-color: #60baeb; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #60baeb; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #33a6e5; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 186, 235, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #365c6c; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #253f4a; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(54, 92, 108, 0.5); }

.badge-success {
  color: #fff;
  background-color: #52c41a; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #3f9714; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 196, 26, 0.5); }

.badge-info {
  color: #fff;
  background-color: #13c2c2; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #0e9494; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 194, 194, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #fce359; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #fbdb27; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 227, 89, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ff4d4f; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ff1a1d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-white {
  color: #212529;
  background-color: #fff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-gray {
  color: #212529;
  background-color: #ced4da; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #212529;
    background-color: #b1bbc4; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #32617a;
  background-color: #dff1fb;
  border-color: #d2ecf9; }
  .alert-primary hr {
    border-top-color: #bce3f6; }
  .alert-primary .alert-link {
    color: #234456; }

.alert-secondary {
  color: #1c3038;
  background-color: #d7dee2;
  border-color: #c7d1d6; }
  .alert-secondary hr {
    border-top-color: #b8c5cb; }
  .alert-secondary .alert-link {
    color: #0b1316; }

.alert-success {
  color: #2b660e;
  background-color: #dcf3d1;
  border-color: #cfeebf; }
  .alert-success hr {
    border-top-color: #c0e9ab; }
  .alert-success .alert-link {
    color: #183908; }

.alert-info {
  color: #0a6565;
  background-color: #d0f3f3;
  border-color: #bdeeee; }
  .alert-info hr {
    border-top-color: #a9e9e9; }
  .alert-info .alert-link {
    color: #053737; }

.alert-warning {
  color: #83762e;
  background-color: #fef9de;
  border-color: #fef7d1; }
  .alert-warning hr {
    border-top-color: #fdf3b8; }
  .alert-warning .alert-link {
    color: #5d5421; }

.alert-danger {
  color: #852829;
  background-color: #ffdbdc;
  border-color: #ffcdce; }
  .alert-danger hr {
    border-top-color: #ffb4b5; }
  .alert-danger .alert-link {
    color: #5e1c1d; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-gray {
  color: #6b6e71;
  background-color: #f5f6f8;
  border-color: #f1f3f5; }
  .alert-gray hr {
    border-top-color: #e2e6ea; }
  .alert-gray .alert-link {
    color: #525557; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #60baeb;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #60baeb;
    border-color: #60baeb; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #32617a;
  background-color: #d2ecf9; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #32617a;
    background-color: #bce3f6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #32617a;
    border-color: #32617a; }

.list-group-item-secondary {
  color: #1c3038;
  background-color: #c7d1d6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1c3038;
    background-color: #b8c5cb; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1c3038;
    border-color: #1c3038; }

.list-group-item-success {
  color: #2b660e;
  background-color: #cfeebf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2b660e;
    background-color: #c0e9ab; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #2b660e;
    border-color: #2b660e; }

.list-group-item-info {
  color: #0a6565;
  background-color: #bdeeee; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0a6565;
    background-color: #a9e9e9; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0a6565;
    border-color: #0a6565; }

.list-group-item-warning {
  color: #83762e;
  background-color: #fef7d1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #83762e;
    background-color: #fdf3b8; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #83762e;
    border-color: #83762e; }

.list-group-item-danger {
  color: #852829;
  background-color: #ffcdce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #852829;
    background-color: #ffb4b5; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #852829;
    border-color: #852829; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-gray {
  color: #6b6e71;
  background-color: #f1f3f5; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #6b6e71;
    background-color: #e2e6ea; }
  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #6b6e71;
    border-color: #6b6e71; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #60baeb !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #33a6e5 !important; }

.bg-secondary {
  background-color: #365c6c !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #253f4a !important; }

.bg-success {
  background-color: #52c41a !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3f9714 !important; }

.bg-info {
  background-color: #13c2c2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0e9494 !important; }

.bg-warning {
  background-color: #fce359 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fbdb27 !important; }

.bg-danger {
  background-color: #ff4d4f !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1a1d !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray {
  background-color: #ced4da !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #60baeb !important; }

.border-secondary {
  border-color: #365c6c !important; }

.border-success {
  border-color: #52c41a !important; }

.border-info {
  border-color: #13c2c2 !important; }

.border-warning {
  border-color: #fce359 !important; }

.border-danger {
  border-color: #ff4d4f !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.border-gray {
  border-color: #ced4da !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m--10 {
  margin: -10rem !important; }

.mt--10,
.my--10 {
  margin-top: -10rem !important; }

.mr--10,
.mx--10 {
  margin-right: -10rem !important; }

.mb--10,
.my--10 {
  margin-bottom: -10rem !important; }

.ml--10,
.mx--10 {
  margin-left: -10rem !important; }

.m--7 {
  margin: -5rem !important; }

.mt--7,
.my--7 {
  margin-top: -5rem !important; }

.mr--7,
.mx--7 {
  margin-right: -5rem !important; }

.mb--7,
.my--7 {
  margin-bottom: -5rem !important; }

.ml--7,
.mx--7 {
  margin-left: -5rem !important; }

.m--6 {
  margin: -4rem !important; }

.mt--6,
.my--6 {
  margin-top: -4rem !important; }

.mr--6,
.mx--6 {
  margin-right: -4rem !important; }

.mb--6,
.my--6 {
  margin-bottom: -4rem !important; }

.ml--6,
.mx--6 {
  margin-left: -4rem !important; }

.m--5 {
  margin: -3rem !important; }

.mt--5,
.my--5 {
  margin-top: -3rem !important; }

.mr--5,
.mx--5 {
  margin-right: -3rem !important; }

.mb--5,
.my--5 {
  margin-bottom: -3rem !important; }

.ml--5,
.mx--5 {
  margin-left: -3rem !important; }

.m--4 {
  margin: -1.5rem !important; }

.mt--4,
.my--4 {
  margin-top: -1.5rem !important; }

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important; }

.mb--4,
.my--4 {
  margin-bottom: -1.5rem !important; }

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important; }

.m--3 {
  margin: -1rem !important; }

.mt--3,
.my--3 {
  margin-top: -1rem !important; }

.mr--3,
.mx--3 {
  margin-right: -1rem !important; }

.mb--3,
.my--3 {
  margin-bottom: -1rem !important; }

.ml--3,
.mx--3 {
  margin-left: -1rem !important; }

.m--2 {
  margin: -0.5rem !important; }

.mt--2,
.my--2 {
  margin-top: -0.5rem !important; }

.mr--2,
.mx--2 {
  margin-right: -0.5rem !important; }

.mb--2,
.my--2 {
  margin-bottom: -0.5rem !important; }

.ml--2,
.mx--2 {
  margin-left: -0.5rem !important; }

.m--1 {
  margin: -0.25rem !important; }

.mt--1,
.my--1 {
  margin-top: -0.25rem !important; }

.mr--1,
.mx--1 {
  margin-right: -0.25rem !important; }

.mb--1,
.my--1 {
  margin-bottom: -0.25rem !important; }

.ml--1,
.mx--1 {
  margin-left: -0.25rem !important; }

.m-6 {
  margin: 4rem !important; }

.mt-6,
.my-6 {
  margin-top: 4rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4rem !important; }

.m-7 {
  margin: 5rem !important; }

.mt-7,
.my-7 {
  margin-top: 5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p--10 {
  padding: -10rem !important; }

.pt--10,
.py--10 {
  padding-top: -10rem !important; }

.pr--10,
.px--10 {
  padding-right: -10rem !important; }

.pb--10,
.py--10 {
  padding-bottom: -10rem !important; }

.pl--10,
.px--10 {
  padding-left: -10rem !important; }

.p--7 {
  padding: -5rem !important; }

.pt--7,
.py--7 {
  padding-top: -5rem !important; }

.pr--7,
.px--7 {
  padding-right: -5rem !important; }

.pb--7,
.py--7 {
  padding-bottom: -5rem !important; }

.pl--7,
.px--7 {
  padding-left: -5rem !important; }

.p--6 {
  padding: -4rem !important; }

.pt--6,
.py--6 {
  padding-top: -4rem !important; }

.pr--6,
.px--6 {
  padding-right: -4rem !important; }

.pb--6,
.py--6 {
  padding-bottom: -4rem !important; }

.pl--6,
.px--6 {
  padding-left: -4rem !important; }

.p--5 {
  padding: -3rem !important; }

.pt--5,
.py--5 {
  padding-top: -3rem !important; }

.pr--5,
.px--5 {
  padding-right: -3rem !important; }

.pb--5,
.py--5 {
  padding-bottom: -3rem !important; }

.pl--5,
.px--5 {
  padding-left: -3rem !important; }

.p--4 {
  padding: -1.5rem !important; }

.pt--4,
.py--4 {
  padding-top: -1.5rem !important; }

.pr--4,
.px--4 {
  padding-right: -1.5rem !important; }

.pb--4,
.py--4 {
  padding-bottom: -1.5rem !important; }

.pl--4,
.px--4 {
  padding-left: -1.5rem !important; }

.p--3 {
  padding: -1rem !important; }

.pt--3,
.py--3 {
  padding-top: -1rem !important; }

.pr--3,
.px--3 {
  padding-right: -1rem !important; }

.pb--3,
.py--3 {
  padding-bottom: -1rem !important; }

.pl--3,
.px--3 {
  padding-left: -1rem !important; }

.p--2 {
  padding: -0.5rem !important; }

.pt--2,
.py--2 {
  padding-top: -0.5rem !important; }

.pr--2,
.px--2 {
  padding-right: -0.5rem !important; }

.pb--2,
.py--2 {
  padding-bottom: -0.5rem !important; }

.pl--2,
.px--2 {
  padding-left: -0.5rem !important; }

.p--1 {
  padding: -0.25rem !important; }

.pt--1,
.py--1 {
  padding-top: -0.25rem !important; }

.pr--1,
.px--1 {
  padding-right: -0.25rem !important; }

.pb--1,
.py--1 {
  padding-bottom: -0.25rem !important; }

.pl--1,
.px--1 {
  padding-left: -0.25rem !important; }

.p-6 {
  padding: 4rem !important; }

.pt-6,
.py-6 {
  padding-top: 4rem !important; }

.pr-6,
.px-6 {
  padding-right: 4rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4rem !important; }

.pl-6,
.px-6 {
  padding-left: 4rem !important; }

.p-7 {
  padding: 5rem !important; }

.pt-7,
.py-7 {
  padding-top: 5rem !important; }

.pr-7,
.px-7 {
  padding-right: 5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 5rem !important; }

.pl-7,
.px-7 {
  padding-left: 5rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n-10 {
  margin: 10rem !important; }

.mt-n-10,
.my-n-10 {
  margin-top: 10rem !important; }

.mr-n-10,
.mx-n-10 {
  margin-right: 10rem !important; }

.mb-n-10,
.my-n-10 {
  margin-bottom: 10rem !important; }

.ml-n-10,
.mx-n-10 {
  margin-left: 10rem !important; }

.m-n-7 {
  margin: 5rem !important; }

.mt-n-7,
.my-n-7 {
  margin-top: 5rem !important; }

.mr-n-7,
.mx-n-7 {
  margin-right: 5rem !important; }

.mb-n-7,
.my-n-7 {
  margin-bottom: 5rem !important; }

.ml-n-7,
.mx-n-7 {
  margin-left: 5rem !important; }

.m-n-6 {
  margin: 4rem !important; }

.mt-n-6,
.my-n-6 {
  margin-top: 4rem !important; }

.mr-n-6,
.mx-n-6 {
  margin-right: 4rem !important; }

.mb-n-6,
.my-n-6 {
  margin-bottom: 4rem !important; }

.ml-n-6,
.mx-n-6 {
  margin-left: 4rem !important; }

.m-n-5 {
  margin: 3rem !important; }

.mt-n-5,
.my-n-5 {
  margin-top: 3rem !important; }

.mr-n-5,
.mx-n-5 {
  margin-right: 3rem !important; }

.mb-n-5,
.my-n-5 {
  margin-bottom: 3rem !important; }

.ml-n-5,
.mx-n-5 {
  margin-left: 3rem !important; }

.m-n-4 {
  margin: 1.5rem !important; }

.mt-n-4,
.my-n-4 {
  margin-top: 1.5rem !important; }

.mr-n-4,
.mx-n-4 {
  margin-right: 1.5rem !important; }

.mb-n-4,
.my-n-4 {
  margin-bottom: 1.5rem !important; }

.ml-n-4,
.mx-n-4 {
  margin-left: 1.5rem !important; }

.m-n-3 {
  margin: 1rem !important; }

.mt-n-3,
.my-n-3 {
  margin-top: 1rem !important; }

.mr-n-3,
.mx-n-3 {
  margin-right: 1rem !important; }

.mb-n-3,
.my-n-3 {
  margin-bottom: 1rem !important; }

.ml-n-3,
.mx-n-3 {
  margin-left: 1rem !important; }

.m-n-2 {
  margin: 0.5rem !important; }

.mt-n-2,
.my-n-2 {
  margin-top: 0.5rem !important; }

.mr-n-2,
.mx-n-2 {
  margin-right: 0.5rem !important; }

.mb-n-2,
.my-n-2 {
  margin-bottom: 0.5rem !important; }

.ml-n-2,
.mx-n-2 {
  margin-left: 0.5rem !important; }

.m-n-1 {
  margin: 0.25rem !important; }

.mt-n-1,
.my-n-1 {
  margin-top: 0.25rem !important; }

.mr-n-1,
.mx-n-1 {
  margin-right: 0.25rem !important; }

.mb-n-1,
.my-n-1 {
  margin-bottom: 0.25rem !important; }

.ml-n-1,
.mx-n-1 {
  margin-left: 0.25rem !important; }

.m-n6 {
  margin: -4rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important; }

.m-n7 {
  margin: -5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm--10 {
    margin: -10rem !important; }
  .mt-sm--10,
  .my-sm--10 {
    margin-top: -10rem !important; }
  .mr-sm--10,
  .mx-sm--10 {
    margin-right: -10rem !important; }
  .mb-sm--10,
  .my-sm--10 {
    margin-bottom: -10rem !important; }
  .ml-sm--10,
  .mx-sm--10 {
    margin-left: -10rem !important; }
  .m-sm--7 {
    margin: -5rem !important; }
  .mt-sm--7,
  .my-sm--7 {
    margin-top: -5rem !important; }
  .mr-sm--7,
  .mx-sm--7 {
    margin-right: -5rem !important; }
  .mb-sm--7,
  .my-sm--7 {
    margin-bottom: -5rem !important; }
  .ml-sm--7,
  .mx-sm--7 {
    margin-left: -5rem !important; }
  .m-sm--6 {
    margin: -4rem !important; }
  .mt-sm--6,
  .my-sm--6 {
    margin-top: -4rem !important; }
  .mr-sm--6,
  .mx-sm--6 {
    margin-right: -4rem !important; }
  .mb-sm--6,
  .my-sm--6 {
    margin-bottom: -4rem !important; }
  .ml-sm--6,
  .mx-sm--6 {
    margin-left: -4rem !important; }
  .m-sm--5 {
    margin: -3rem !important; }
  .mt-sm--5,
  .my-sm--5 {
    margin-top: -3rem !important; }
  .mr-sm--5,
  .mx-sm--5 {
    margin-right: -3rem !important; }
  .mb-sm--5,
  .my-sm--5 {
    margin-bottom: -3rem !important; }
  .ml-sm--5,
  .mx-sm--5 {
    margin-left: -3rem !important; }
  .m-sm--4 {
    margin: -1.5rem !important; }
  .mt-sm--4,
  .my-sm--4 {
    margin-top: -1.5rem !important; }
  .mr-sm--4,
  .mx-sm--4 {
    margin-right: -1.5rem !important; }
  .mb-sm--4,
  .my-sm--4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm--4,
  .mx-sm--4 {
    margin-left: -1.5rem !important; }
  .m-sm--3 {
    margin: -1rem !important; }
  .mt-sm--3,
  .my-sm--3 {
    margin-top: -1rem !important; }
  .mr-sm--3,
  .mx-sm--3 {
    margin-right: -1rem !important; }
  .mb-sm--3,
  .my-sm--3 {
    margin-bottom: -1rem !important; }
  .ml-sm--3,
  .mx-sm--3 {
    margin-left: -1rem !important; }
  .m-sm--2 {
    margin: -0.5rem !important; }
  .mt-sm--2,
  .my-sm--2 {
    margin-top: -0.5rem !important; }
  .mr-sm--2,
  .mx-sm--2 {
    margin-right: -0.5rem !important; }
  .mb-sm--2,
  .my-sm--2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm--2,
  .mx-sm--2 {
    margin-left: -0.5rem !important; }
  .m-sm--1 {
    margin: -0.25rem !important; }
  .mt-sm--1,
  .my-sm--1 {
    margin-top: -0.25rem !important; }
  .mr-sm--1,
  .mx-sm--1 {
    margin-right: -0.25rem !important; }
  .mb-sm--1,
  .my-sm--1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm--1,
  .mx-sm--1 {
    margin-left: -0.25rem !important; }
  .m-sm-6 {
    margin: 4rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important; }
  .m-sm-7 {
    margin: 5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm--10 {
    padding: -10rem !important; }
  .pt-sm--10,
  .py-sm--10 {
    padding-top: -10rem !important; }
  .pr-sm--10,
  .px-sm--10 {
    padding-right: -10rem !important; }
  .pb-sm--10,
  .py-sm--10 {
    padding-bottom: -10rem !important; }
  .pl-sm--10,
  .px-sm--10 {
    padding-left: -10rem !important; }
  .p-sm--7 {
    padding: -5rem !important; }
  .pt-sm--7,
  .py-sm--7 {
    padding-top: -5rem !important; }
  .pr-sm--7,
  .px-sm--7 {
    padding-right: -5rem !important; }
  .pb-sm--7,
  .py-sm--7 {
    padding-bottom: -5rem !important; }
  .pl-sm--7,
  .px-sm--7 {
    padding-left: -5rem !important; }
  .p-sm--6 {
    padding: -4rem !important; }
  .pt-sm--6,
  .py-sm--6 {
    padding-top: -4rem !important; }
  .pr-sm--6,
  .px-sm--6 {
    padding-right: -4rem !important; }
  .pb-sm--6,
  .py-sm--6 {
    padding-bottom: -4rem !important; }
  .pl-sm--6,
  .px-sm--6 {
    padding-left: -4rem !important; }
  .p-sm--5 {
    padding: -3rem !important; }
  .pt-sm--5,
  .py-sm--5 {
    padding-top: -3rem !important; }
  .pr-sm--5,
  .px-sm--5 {
    padding-right: -3rem !important; }
  .pb-sm--5,
  .py-sm--5 {
    padding-bottom: -3rem !important; }
  .pl-sm--5,
  .px-sm--5 {
    padding-left: -3rem !important; }
  .p-sm--4 {
    padding: -1.5rem !important; }
  .pt-sm--4,
  .py-sm--4 {
    padding-top: -1.5rem !important; }
  .pr-sm--4,
  .px-sm--4 {
    padding-right: -1.5rem !important; }
  .pb-sm--4,
  .py-sm--4 {
    padding-bottom: -1.5rem !important; }
  .pl-sm--4,
  .px-sm--4 {
    padding-left: -1.5rem !important; }
  .p-sm--3 {
    padding: -1rem !important; }
  .pt-sm--3,
  .py-sm--3 {
    padding-top: -1rem !important; }
  .pr-sm--3,
  .px-sm--3 {
    padding-right: -1rem !important; }
  .pb-sm--3,
  .py-sm--3 {
    padding-bottom: -1rem !important; }
  .pl-sm--3,
  .px-sm--3 {
    padding-left: -1rem !important; }
  .p-sm--2 {
    padding: -0.5rem !important; }
  .pt-sm--2,
  .py-sm--2 {
    padding-top: -0.5rem !important; }
  .pr-sm--2,
  .px-sm--2 {
    padding-right: -0.5rem !important; }
  .pb-sm--2,
  .py-sm--2 {
    padding-bottom: -0.5rem !important; }
  .pl-sm--2,
  .px-sm--2 {
    padding-left: -0.5rem !important; }
  .p-sm--1 {
    padding: -0.25rem !important; }
  .pt-sm--1,
  .py-sm--1 {
    padding-top: -0.25rem !important; }
  .pr-sm--1,
  .px-sm--1 {
    padding-right: -0.25rem !important; }
  .pb-sm--1,
  .py-sm--1 {
    padding-bottom: -0.25rem !important; }
  .pl-sm--1,
  .px-sm--1 {
    padding-left: -0.25rem !important; }
  .p-sm-6 {
    padding: 4rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important; }
  .p-sm-7 {
    padding: 5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n-10 {
    margin: 10rem !important; }
  .mt-sm-n-10,
  .my-sm-n-10 {
    margin-top: 10rem !important; }
  .mr-sm-n-10,
  .mx-sm-n-10 {
    margin-right: 10rem !important; }
  .mb-sm-n-10,
  .my-sm-n-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-n-10,
  .mx-sm-n-10 {
    margin-left: 10rem !important; }
  .m-sm-n-7 {
    margin: 5rem !important; }
  .mt-sm-n-7,
  .my-sm-n-7 {
    margin-top: 5rem !important; }
  .mr-sm-n-7,
  .mx-sm-n-7 {
    margin-right: 5rem !important; }
  .mb-sm-n-7,
  .my-sm-n-7 {
    margin-bottom: 5rem !important; }
  .ml-sm-n-7,
  .mx-sm-n-7 {
    margin-left: 5rem !important; }
  .m-sm-n-6 {
    margin: 4rem !important; }
  .mt-sm-n-6,
  .my-sm-n-6 {
    margin-top: 4rem !important; }
  .mr-sm-n-6,
  .mx-sm-n-6 {
    margin-right: 4rem !important; }
  .mb-sm-n-6,
  .my-sm-n-6 {
    margin-bottom: 4rem !important; }
  .ml-sm-n-6,
  .mx-sm-n-6 {
    margin-left: 4rem !important; }
  .m-sm-n-5 {
    margin: 3rem !important; }
  .mt-sm-n-5,
  .my-sm-n-5 {
    margin-top: 3rem !important; }
  .mr-sm-n-5,
  .mx-sm-n-5 {
    margin-right: 3rem !important; }
  .mb-sm-n-5,
  .my-sm-n-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-n-5,
  .mx-sm-n-5 {
    margin-left: 3rem !important; }
  .m-sm-n-4 {
    margin: 1.5rem !important; }
  .mt-sm-n-4,
  .my-sm-n-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-n-4,
  .mx-sm-n-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-n-4,
  .my-sm-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-n-4,
  .mx-sm-n-4 {
    margin-left: 1.5rem !important; }
  .m-sm-n-3 {
    margin: 1rem !important; }
  .mt-sm-n-3,
  .my-sm-n-3 {
    margin-top: 1rem !important; }
  .mr-sm-n-3,
  .mx-sm-n-3 {
    margin-right: 1rem !important; }
  .mb-sm-n-3,
  .my-sm-n-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-n-3,
  .mx-sm-n-3 {
    margin-left: 1rem !important; }
  .m-sm-n-2 {
    margin: 0.5rem !important; }
  .mt-sm-n-2,
  .my-sm-n-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-n-2,
  .mx-sm-n-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-n-2,
  .my-sm-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-n-2,
  .mx-sm-n-2 {
    margin-left: 0.5rem !important; }
  .m-sm-n-1 {
    margin: 0.25rem !important; }
  .mt-sm-n-1,
  .my-sm-n-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-n-1,
  .mx-sm-n-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-n-1,
  .my-sm-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-n-1,
  .mx-sm-n-1 {
    margin-left: 0.25rem !important; }
  .m-sm-n6 {
    margin: -4rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important; }
  .m-sm-n7 {
    margin: -5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md--10 {
    margin: -10rem !important; }
  .mt-md--10,
  .my-md--10 {
    margin-top: -10rem !important; }
  .mr-md--10,
  .mx-md--10 {
    margin-right: -10rem !important; }
  .mb-md--10,
  .my-md--10 {
    margin-bottom: -10rem !important; }
  .ml-md--10,
  .mx-md--10 {
    margin-left: -10rem !important; }
  .m-md--7 {
    margin: -5rem !important; }
  .mt-md--7,
  .my-md--7 {
    margin-top: -5rem !important; }
  .mr-md--7,
  .mx-md--7 {
    margin-right: -5rem !important; }
  .mb-md--7,
  .my-md--7 {
    margin-bottom: -5rem !important; }
  .ml-md--7,
  .mx-md--7 {
    margin-left: -5rem !important; }
  .m-md--6 {
    margin: -4rem !important; }
  .mt-md--6,
  .my-md--6 {
    margin-top: -4rem !important; }
  .mr-md--6,
  .mx-md--6 {
    margin-right: -4rem !important; }
  .mb-md--6,
  .my-md--6 {
    margin-bottom: -4rem !important; }
  .ml-md--6,
  .mx-md--6 {
    margin-left: -4rem !important; }
  .m-md--5 {
    margin: -3rem !important; }
  .mt-md--5,
  .my-md--5 {
    margin-top: -3rem !important; }
  .mr-md--5,
  .mx-md--5 {
    margin-right: -3rem !important; }
  .mb-md--5,
  .my-md--5 {
    margin-bottom: -3rem !important; }
  .ml-md--5,
  .mx-md--5 {
    margin-left: -3rem !important; }
  .m-md--4 {
    margin: -1.5rem !important; }
  .mt-md--4,
  .my-md--4 {
    margin-top: -1.5rem !important; }
  .mr-md--4,
  .mx-md--4 {
    margin-right: -1.5rem !important; }
  .mb-md--4,
  .my-md--4 {
    margin-bottom: -1.5rem !important; }
  .ml-md--4,
  .mx-md--4 {
    margin-left: -1.5rem !important; }
  .m-md--3 {
    margin: -1rem !important; }
  .mt-md--3,
  .my-md--3 {
    margin-top: -1rem !important; }
  .mr-md--3,
  .mx-md--3 {
    margin-right: -1rem !important; }
  .mb-md--3,
  .my-md--3 {
    margin-bottom: -1rem !important; }
  .ml-md--3,
  .mx-md--3 {
    margin-left: -1rem !important; }
  .m-md--2 {
    margin: -0.5rem !important; }
  .mt-md--2,
  .my-md--2 {
    margin-top: -0.5rem !important; }
  .mr-md--2,
  .mx-md--2 {
    margin-right: -0.5rem !important; }
  .mb-md--2,
  .my-md--2 {
    margin-bottom: -0.5rem !important; }
  .ml-md--2,
  .mx-md--2 {
    margin-left: -0.5rem !important; }
  .m-md--1 {
    margin: -0.25rem !important; }
  .mt-md--1,
  .my-md--1 {
    margin-top: -0.25rem !important; }
  .mr-md--1,
  .mx-md--1 {
    margin-right: -0.25rem !important; }
  .mb-md--1,
  .my-md--1 {
    margin-bottom: -0.25rem !important; }
  .ml-md--1,
  .mx-md--1 {
    margin-left: -0.25rem !important; }
  .m-md-6 {
    margin: 4rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important; }
  .m-md-7 {
    margin: 5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md--10 {
    padding: -10rem !important; }
  .pt-md--10,
  .py-md--10 {
    padding-top: -10rem !important; }
  .pr-md--10,
  .px-md--10 {
    padding-right: -10rem !important; }
  .pb-md--10,
  .py-md--10 {
    padding-bottom: -10rem !important; }
  .pl-md--10,
  .px-md--10 {
    padding-left: -10rem !important; }
  .p-md--7 {
    padding: -5rem !important; }
  .pt-md--7,
  .py-md--7 {
    padding-top: -5rem !important; }
  .pr-md--7,
  .px-md--7 {
    padding-right: -5rem !important; }
  .pb-md--7,
  .py-md--7 {
    padding-bottom: -5rem !important; }
  .pl-md--7,
  .px-md--7 {
    padding-left: -5rem !important; }
  .p-md--6 {
    padding: -4rem !important; }
  .pt-md--6,
  .py-md--6 {
    padding-top: -4rem !important; }
  .pr-md--6,
  .px-md--6 {
    padding-right: -4rem !important; }
  .pb-md--6,
  .py-md--6 {
    padding-bottom: -4rem !important; }
  .pl-md--6,
  .px-md--6 {
    padding-left: -4rem !important; }
  .p-md--5 {
    padding: -3rem !important; }
  .pt-md--5,
  .py-md--5 {
    padding-top: -3rem !important; }
  .pr-md--5,
  .px-md--5 {
    padding-right: -3rem !important; }
  .pb-md--5,
  .py-md--5 {
    padding-bottom: -3rem !important; }
  .pl-md--5,
  .px-md--5 {
    padding-left: -3rem !important; }
  .p-md--4 {
    padding: -1.5rem !important; }
  .pt-md--4,
  .py-md--4 {
    padding-top: -1.5rem !important; }
  .pr-md--4,
  .px-md--4 {
    padding-right: -1.5rem !important; }
  .pb-md--4,
  .py-md--4 {
    padding-bottom: -1.5rem !important; }
  .pl-md--4,
  .px-md--4 {
    padding-left: -1.5rem !important; }
  .p-md--3 {
    padding: -1rem !important; }
  .pt-md--3,
  .py-md--3 {
    padding-top: -1rem !important; }
  .pr-md--3,
  .px-md--3 {
    padding-right: -1rem !important; }
  .pb-md--3,
  .py-md--3 {
    padding-bottom: -1rem !important; }
  .pl-md--3,
  .px-md--3 {
    padding-left: -1rem !important; }
  .p-md--2 {
    padding: -0.5rem !important; }
  .pt-md--2,
  .py-md--2 {
    padding-top: -0.5rem !important; }
  .pr-md--2,
  .px-md--2 {
    padding-right: -0.5rem !important; }
  .pb-md--2,
  .py-md--2 {
    padding-bottom: -0.5rem !important; }
  .pl-md--2,
  .px-md--2 {
    padding-left: -0.5rem !important; }
  .p-md--1 {
    padding: -0.25rem !important; }
  .pt-md--1,
  .py-md--1 {
    padding-top: -0.25rem !important; }
  .pr-md--1,
  .px-md--1 {
    padding-right: -0.25rem !important; }
  .pb-md--1,
  .py-md--1 {
    padding-bottom: -0.25rem !important; }
  .pl-md--1,
  .px-md--1 {
    padding-left: -0.25rem !important; }
  .p-md-6 {
    padding: 4rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important; }
  .p-md-7 {
    padding: 5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n-10 {
    margin: 10rem !important; }
  .mt-md-n-10,
  .my-md-n-10 {
    margin-top: 10rem !important; }
  .mr-md-n-10,
  .mx-md-n-10 {
    margin-right: 10rem !important; }
  .mb-md-n-10,
  .my-md-n-10 {
    margin-bottom: 10rem !important; }
  .ml-md-n-10,
  .mx-md-n-10 {
    margin-left: 10rem !important; }
  .m-md-n-7 {
    margin: 5rem !important; }
  .mt-md-n-7,
  .my-md-n-7 {
    margin-top: 5rem !important; }
  .mr-md-n-7,
  .mx-md-n-7 {
    margin-right: 5rem !important; }
  .mb-md-n-7,
  .my-md-n-7 {
    margin-bottom: 5rem !important; }
  .ml-md-n-7,
  .mx-md-n-7 {
    margin-left: 5rem !important; }
  .m-md-n-6 {
    margin: 4rem !important; }
  .mt-md-n-6,
  .my-md-n-6 {
    margin-top: 4rem !important; }
  .mr-md-n-6,
  .mx-md-n-6 {
    margin-right: 4rem !important; }
  .mb-md-n-6,
  .my-md-n-6 {
    margin-bottom: 4rem !important; }
  .ml-md-n-6,
  .mx-md-n-6 {
    margin-left: 4rem !important; }
  .m-md-n-5 {
    margin: 3rem !important; }
  .mt-md-n-5,
  .my-md-n-5 {
    margin-top: 3rem !important; }
  .mr-md-n-5,
  .mx-md-n-5 {
    margin-right: 3rem !important; }
  .mb-md-n-5,
  .my-md-n-5 {
    margin-bottom: 3rem !important; }
  .ml-md-n-5,
  .mx-md-n-5 {
    margin-left: 3rem !important; }
  .m-md-n-4 {
    margin: 1.5rem !important; }
  .mt-md-n-4,
  .my-md-n-4 {
    margin-top: 1.5rem !important; }
  .mr-md-n-4,
  .mx-md-n-4 {
    margin-right: 1.5rem !important; }
  .mb-md-n-4,
  .my-md-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-n-4,
  .mx-md-n-4 {
    margin-left: 1.5rem !important; }
  .m-md-n-3 {
    margin: 1rem !important; }
  .mt-md-n-3,
  .my-md-n-3 {
    margin-top: 1rem !important; }
  .mr-md-n-3,
  .mx-md-n-3 {
    margin-right: 1rem !important; }
  .mb-md-n-3,
  .my-md-n-3 {
    margin-bottom: 1rem !important; }
  .ml-md-n-3,
  .mx-md-n-3 {
    margin-left: 1rem !important; }
  .m-md-n-2 {
    margin: 0.5rem !important; }
  .mt-md-n-2,
  .my-md-n-2 {
    margin-top: 0.5rem !important; }
  .mr-md-n-2,
  .mx-md-n-2 {
    margin-right: 0.5rem !important; }
  .mb-md-n-2,
  .my-md-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-n-2,
  .mx-md-n-2 {
    margin-left: 0.5rem !important; }
  .m-md-n-1 {
    margin: 0.25rem !important; }
  .mt-md-n-1,
  .my-md-n-1 {
    margin-top: 0.25rem !important; }
  .mr-md-n-1,
  .mx-md-n-1 {
    margin-right: 0.25rem !important; }
  .mb-md-n-1,
  .my-md-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-n-1,
  .mx-md-n-1 {
    margin-left: 0.25rem !important; }
  .m-md-n6 {
    margin: -4rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important; }
  .m-md-n7 {
    margin: -5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg--10 {
    margin: -10rem !important; }
  .mt-lg--10,
  .my-lg--10 {
    margin-top: -10rem !important; }
  .mr-lg--10,
  .mx-lg--10 {
    margin-right: -10rem !important; }
  .mb-lg--10,
  .my-lg--10 {
    margin-bottom: -10rem !important; }
  .ml-lg--10,
  .mx-lg--10 {
    margin-left: -10rem !important; }
  .m-lg--7 {
    margin: -5rem !important; }
  .mt-lg--7,
  .my-lg--7 {
    margin-top: -5rem !important; }
  .mr-lg--7,
  .mx-lg--7 {
    margin-right: -5rem !important; }
  .mb-lg--7,
  .my-lg--7 {
    margin-bottom: -5rem !important; }
  .ml-lg--7,
  .mx-lg--7 {
    margin-left: -5rem !important; }
  .m-lg--6 {
    margin: -4rem !important; }
  .mt-lg--6,
  .my-lg--6 {
    margin-top: -4rem !important; }
  .mr-lg--6,
  .mx-lg--6 {
    margin-right: -4rem !important; }
  .mb-lg--6,
  .my-lg--6 {
    margin-bottom: -4rem !important; }
  .ml-lg--6,
  .mx-lg--6 {
    margin-left: -4rem !important; }
  .m-lg--5 {
    margin: -3rem !important; }
  .mt-lg--5,
  .my-lg--5 {
    margin-top: -3rem !important; }
  .mr-lg--5,
  .mx-lg--5 {
    margin-right: -3rem !important; }
  .mb-lg--5,
  .my-lg--5 {
    margin-bottom: -3rem !important; }
  .ml-lg--5,
  .mx-lg--5 {
    margin-left: -3rem !important; }
  .m-lg--4 {
    margin: -1.5rem !important; }
  .mt-lg--4,
  .my-lg--4 {
    margin-top: -1.5rem !important; }
  .mr-lg--4,
  .mx-lg--4 {
    margin-right: -1.5rem !important; }
  .mb-lg--4,
  .my-lg--4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg--4,
  .mx-lg--4 {
    margin-left: -1.5rem !important; }
  .m-lg--3 {
    margin: -1rem !important; }
  .mt-lg--3,
  .my-lg--3 {
    margin-top: -1rem !important; }
  .mr-lg--3,
  .mx-lg--3 {
    margin-right: -1rem !important; }
  .mb-lg--3,
  .my-lg--3 {
    margin-bottom: -1rem !important; }
  .ml-lg--3,
  .mx-lg--3 {
    margin-left: -1rem !important; }
  .m-lg--2 {
    margin: -0.5rem !important; }
  .mt-lg--2,
  .my-lg--2 {
    margin-top: -0.5rem !important; }
  .mr-lg--2,
  .mx-lg--2 {
    margin-right: -0.5rem !important; }
  .mb-lg--2,
  .my-lg--2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg--2,
  .mx-lg--2 {
    margin-left: -0.5rem !important; }
  .m-lg--1 {
    margin: -0.25rem !important; }
  .mt-lg--1,
  .my-lg--1 {
    margin-top: -0.25rem !important; }
  .mr-lg--1,
  .mx-lg--1 {
    margin-right: -0.25rem !important; }
  .mb-lg--1,
  .my-lg--1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg--1,
  .mx-lg--1 {
    margin-left: -0.25rem !important; }
  .m-lg-6 {
    margin: 4rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important; }
  .m-lg-7 {
    margin: 5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg--10 {
    padding: -10rem !important; }
  .pt-lg--10,
  .py-lg--10 {
    padding-top: -10rem !important; }
  .pr-lg--10,
  .px-lg--10 {
    padding-right: -10rem !important; }
  .pb-lg--10,
  .py-lg--10 {
    padding-bottom: -10rem !important; }
  .pl-lg--10,
  .px-lg--10 {
    padding-left: -10rem !important; }
  .p-lg--7 {
    padding: -5rem !important; }
  .pt-lg--7,
  .py-lg--7 {
    padding-top: -5rem !important; }
  .pr-lg--7,
  .px-lg--7 {
    padding-right: -5rem !important; }
  .pb-lg--7,
  .py-lg--7 {
    padding-bottom: -5rem !important; }
  .pl-lg--7,
  .px-lg--7 {
    padding-left: -5rem !important; }
  .p-lg--6 {
    padding: -4rem !important; }
  .pt-lg--6,
  .py-lg--6 {
    padding-top: -4rem !important; }
  .pr-lg--6,
  .px-lg--6 {
    padding-right: -4rem !important; }
  .pb-lg--6,
  .py-lg--6 {
    padding-bottom: -4rem !important; }
  .pl-lg--6,
  .px-lg--6 {
    padding-left: -4rem !important; }
  .p-lg--5 {
    padding: -3rem !important; }
  .pt-lg--5,
  .py-lg--5 {
    padding-top: -3rem !important; }
  .pr-lg--5,
  .px-lg--5 {
    padding-right: -3rem !important; }
  .pb-lg--5,
  .py-lg--5 {
    padding-bottom: -3rem !important; }
  .pl-lg--5,
  .px-lg--5 {
    padding-left: -3rem !important; }
  .p-lg--4 {
    padding: -1.5rem !important; }
  .pt-lg--4,
  .py-lg--4 {
    padding-top: -1.5rem !important; }
  .pr-lg--4,
  .px-lg--4 {
    padding-right: -1.5rem !important; }
  .pb-lg--4,
  .py-lg--4 {
    padding-bottom: -1.5rem !important; }
  .pl-lg--4,
  .px-lg--4 {
    padding-left: -1.5rem !important; }
  .p-lg--3 {
    padding: -1rem !important; }
  .pt-lg--3,
  .py-lg--3 {
    padding-top: -1rem !important; }
  .pr-lg--3,
  .px-lg--3 {
    padding-right: -1rem !important; }
  .pb-lg--3,
  .py-lg--3 {
    padding-bottom: -1rem !important; }
  .pl-lg--3,
  .px-lg--3 {
    padding-left: -1rem !important; }
  .p-lg--2 {
    padding: -0.5rem !important; }
  .pt-lg--2,
  .py-lg--2 {
    padding-top: -0.5rem !important; }
  .pr-lg--2,
  .px-lg--2 {
    padding-right: -0.5rem !important; }
  .pb-lg--2,
  .py-lg--2 {
    padding-bottom: -0.5rem !important; }
  .pl-lg--2,
  .px-lg--2 {
    padding-left: -0.5rem !important; }
  .p-lg--1 {
    padding: -0.25rem !important; }
  .pt-lg--1,
  .py-lg--1 {
    padding-top: -0.25rem !important; }
  .pr-lg--1,
  .px-lg--1 {
    padding-right: -0.25rem !important; }
  .pb-lg--1,
  .py-lg--1 {
    padding-bottom: -0.25rem !important; }
  .pl-lg--1,
  .px-lg--1 {
    padding-left: -0.25rem !important; }
  .p-lg-6 {
    padding: 4rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important; }
  .p-lg-7 {
    padding: 5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n-10 {
    margin: 10rem !important; }
  .mt-lg-n-10,
  .my-lg-n-10 {
    margin-top: 10rem !important; }
  .mr-lg-n-10,
  .mx-lg-n-10 {
    margin-right: 10rem !important; }
  .mb-lg-n-10,
  .my-lg-n-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-n-10,
  .mx-lg-n-10 {
    margin-left: 10rem !important; }
  .m-lg-n-7 {
    margin: 5rem !important; }
  .mt-lg-n-7,
  .my-lg-n-7 {
    margin-top: 5rem !important; }
  .mr-lg-n-7,
  .mx-lg-n-7 {
    margin-right: 5rem !important; }
  .mb-lg-n-7,
  .my-lg-n-7 {
    margin-bottom: 5rem !important; }
  .ml-lg-n-7,
  .mx-lg-n-7 {
    margin-left: 5rem !important; }
  .m-lg-n-6 {
    margin: 4rem !important; }
  .mt-lg-n-6,
  .my-lg-n-6 {
    margin-top: 4rem !important; }
  .mr-lg-n-6,
  .mx-lg-n-6 {
    margin-right: 4rem !important; }
  .mb-lg-n-6,
  .my-lg-n-6 {
    margin-bottom: 4rem !important; }
  .ml-lg-n-6,
  .mx-lg-n-6 {
    margin-left: 4rem !important; }
  .m-lg-n-5 {
    margin: 3rem !important; }
  .mt-lg-n-5,
  .my-lg-n-5 {
    margin-top: 3rem !important; }
  .mr-lg-n-5,
  .mx-lg-n-5 {
    margin-right: 3rem !important; }
  .mb-lg-n-5,
  .my-lg-n-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-n-5,
  .mx-lg-n-5 {
    margin-left: 3rem !important; }
  .m-lg-n-4 {
    margin: 1.5rem !important; }
  .mt-lg-n-4,
  .my-lg-n-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-n-4,
  .mx-lg-n-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-n-4,
  .my-lg-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-n-4,
  .mx-lg-n-4 {
    margin-left: 1.5rem !important; }
  .m-lg-n-3 {
    margin: 1rem !important; }
  .mt-lg-n-3,
  .my-lg-n-3 {
    margin-top: 1rem !important; }
  .mr-lg-n-3,
  .mx-lg-n-3 {
    margin-right: 1rem !important; }
  .mb-lg-n-3,
  .my-lg-n-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-n-3,
  .mx-lg-n-3 {
    margin-left: 1rem !important; }
  .m-lg-n-2 {
    margin: 0.5rem !important; }
  .mt-lg-n-2,
  .my-lg-n-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-n-2,
  .mx-lg-n-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-n-2,
  .my-lg-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-n-2,
  .mx-lg-n-2 {
    margin-left: 0.5rem !important; }
  .m-lg-n-1 {
    margin: 0.25rem !important; }
  .mt-lg-n-1,
  .my-lg-n-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-n-1,
  .mx-lg-n-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-n-1,
  .my-lg-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-n-1,
  .mx-lg-n-1 {
    margin-left: 0.25rem !important; }
  .m-lg-n6 {
    margin: -4rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important; }
  .m-lg-n7 {
    margin: -5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl--10 {
    margin: -10rem !important; }
  .mt-xl--10,
  .my-xl--10 {
    margin-top: -10rem !important; }
  .mr-xl--10,
  .mx-xl--10 {
    margin-right: -10rem !important; }
  .mb-xl--10,
  .my-xl--10 {
    margin-bottom: -10rem !important; }
  .ml-xl--10,
  .mx-xl--10 {
    margin-left: -10rem !important; }
  .m-xl--7 {
    margin: -5rem !important; }
  .mt-xl--7,
  .my-xl--7 {
    margin-top: -5rem !important; }
  .mr-xl--7,
  .mx-xl--7 {
    margin-right: -5rem !important; }
  .mb-xl--7,
  .my-xl--7 {
    margin-bottom: -5rem !important; }
  .ml-xl--7,
  .mx-xl--7 {
    margin-left: -5rem !important; }
  .m-xl--6 {
    margin: -4rem !important; }
  .mt-xl--6,
  .my-xl--6 {
    margin-top: -4rem !important; }
  .mr-xl--6,
  .mx-xl--6 {
    margin-right: -4rem !important; }
  .mb-xl--6,
  .my-xl--6 {
    margin-bottom: -4rem !important; }
  .ml-xl--6,
  .mx-xl--6 {
    margin-left: -4rem !important; }
  .m-xl--5 {
    margin: -3rem !important; }
  .mt-xl--5,
  .my-xl--5 {
    margin-top: -3rem !important; }
  .mr-xl--5,
  .mx-xl--5 {
    margin-right: -3rem !important; }
  .mb-xl--5,
  .my-xl--5 {
    margin-bottom: -3rem !important; }
  .ml-xl--5,
  .mx-xl--5 {
    margin-left: -3rem !important; }
  .m-xl--4 {
    margin: -1.5rem !important; }
  .mt-xl--4,
  .my-xl--4 {
    margin-top: -1.5rem !important; }
  .mr-xl--4,
  .mx-xl--4 {
    margin-right: -1.5rem !important; }
  .mb-xl--4,
  .my-xl--4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl--4,
  .mx-xl--4 {
    margin-left: -1.5rem !important; }
  .m-xl--3 {
    margin: -1rem !important; }
  .mt-xl--3,
  .my-xl--3 {
    margin-top: -1rem !important; }
  .mr-xl--3,
  .mx-xl--3 {
    margin-right: -1rem !important; }
  .mb-xl--3,
  .my-xl--3 {
    margin-bottom: -1rem !important; }
  .ml-xl--3,
  .mx-xl--3 {
    margin-left: -1rem !important; }
  .m-xl--2 {
    margin: -0.5rem !important; }
  .mt-xl--2,
  .my-xl--2 {
    margin-top: -0.5rem !important; }
  .mr-xl--2,
  .mx-xl--2 {
    margin-right: -0.5rem !important; }
  .mb-xl--2,
  .my-xl--2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl--2,
  .mx-xl--2 {
    margin-left: -0.5rem !important; }
  .m-xl--1 {
    margin: -0.25rem !important; }
  .mt-xl--1,
  .my-xl--1 {
    margin-top: -0.25rem !important; }
  .mr-xl--1,
  .mx-xl--1 {
    margin-right: -0.25rem !important; }
  .mb-xl--1,
  .my-xl--1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl--1,
  .mx-xl--1 {
    margin-left: -0.25rem !important; }
  .m-xl-6 {
    margin: 4rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important; }
  .m-xl-7 {
    margin: 5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl--10 {
    padding: -10rem !important; }
  .pt-xl--10,
  .py-xl--10 {
    padding-top: -10rem !important; }
  .pr-xl--10,
  .px-xl--10 {
    padding-right: -10rem !important; }
  .pb-xl--10,
  .py-xl--10 {
    padding-bottom: -10rem !important; }
  .pl-xl--10,
  .px-xl--10 {
    padding-left: -10rem !important; }
  .p-xl--7 {
    padding: -5rem !important; }
  .pt-xl--7,
  .py-xl--7 {
    padding-top: -5rem !important; }
  .pr-xl--7,
  .px-xl--7 {
    padding-right: -5rem !important; }
  .pb-xl--7,
  .py-xl--7 {
    padding-bottom: -5rem !important; }
  .pl-xl--7,
  .px-xl--7 {
    padding-left: -5rem !important; }
  .p-xl--6 {
    padding: -4rem !important; }
  .pt-xl--6,
  .py-xl--6 {
    padding-top: -4rem !important; }
  .pr-xl--6,
  .px-xl--6 {
    padding-right: -4rem !important; }
  .pb-xl--6,
  .py-xl--6 {
    padding-bottom: -4rem !important; }
  .pl-xl--6,
  .px-xl--6 {
    padding-left: -4rem !important; }
  .p-xl--5 {
    padding: -3rem !important; }
  .pt-xl--5,
  .py-xl--5 {
    padding-top: -3rem !important; }
  .pr-xl--5,
  .px-xl--5 {
    padding-right: -3rem !important; }
  .pb-xl--5,
  .py-xl--5 {
    padding-bottom: -3rem !important; }
  .pl-xl--5,
  .px-xl--5 {
    padding-left: -3rem !important; }
  .p-xl--4 {
    padding: -1.5rem !important; }
  .pt-xl--4,
  .py-xl--4 {
    padding-top: -1.5rem !important; }
  .pr-xl--4,
  .px-xl--4 {
    padding-right: -1.5rem !important; }
  .pb-xl--4,
  .py-xl--4 {
    padding-bottom: -1.5rem !important; }
  .pl-xl--4,
  .px-xl--4 {
    padding-left: -1.5rem !important; }
  .p-xl--3 {
    padding: -1rem !important; }
  .pt-xl--3,
  .py-xl--3 {
    padding-top: -1rem !important; }
  .pr-xl--3,
  .px-xl--3 {
    padding-right: -1rem !important; }
  .pb-xl--3,
  .py-xl--3 {
    padding-bottom: -1rem !important; }
  .pl-xl--3,
  .px-xl--3 {
    padding-left: -1rem !important; }
  .p-xl--2 {
    padding: -0.5rem !important; }
  .pt-xl--2,
  .py-xl--2 {
    padding-top: -0.5rem !important; }
  .pr-xl--2,
  .px-xl--2 {
    padding-right: -0.5rem !important; }
  .pb-xl--2,
  .py-xl--2 {
    padding-bottom: -0.5rem !important; }
  .pl-xl--2,
  .px-xl--2 {
    padding-left: -0.5rem !important; }
  .p-xl--1 {
    padding: -0.25rem !important; }
  .pt-xl--1,
  .py-xl--1 {
    padding-top: -0.25rem !important; }
  .pr-xl--1,
  .px-xl--1 {
    padding-right: -0.25rem !important; }
  .pb-xl--1,
  .py-xl--1 {
    padding-bottom: -0.25rem !important; }
  .pl-xl--1,
  .px-xl--1 {
    padding-left: -0.25rem !important; }
  .p-xl-6 {
    padding: 4rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important; }
  .p-xl-7 {
    padding: 5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n-10 {
    margin: 10rem !important; }
  .mt-xl-n-10,
  .my-xl-n-10 {
    margin-top: 10rem !important; }
  .mr-xl-n-10,
  .mx-xl-n-10 {
    margin-right: 10rem !important; }
  .mb-xl-n-10,
  .my-xl-n-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-n-10,
  .mx-xl-n-10 {
    margin-left: 10rem !important; }
  .m-xl-n-7 {
    margin: 5rem !important; }
  .mt-xl-n-7,
  .my-xl-n-7 {
    margin-top: 5rem !important; }
  .mr-xl-n-7,
  .mx-xl-n-7 {
    margin-right: 5rem !important; }
  .mb-xl-n-7,
  .my-xl-n-7 {
    margin-bottom: 5rem !important; }
  .ml-xl-n-7,
  .mx-xl-n-7 {
    margin-left: 5rem !important; }
  .m-xl-n-6 {
    margin: 4rem !important; }
  .mt-xl-n-6,
  .my-xl-n-6 {
    margin-top: 4rem !important; }
  .mr-xl-n-6,
  .mx-xl-n-6 {
    margin-right: 4rem !important; }
  .mb-xl-n-6,
  .my-xl-n-6 {
    margin-bottom: 4rem !important; }
  .ml-xl-n-6,
  .mx-xl-n-6 {
    margin-left: 4rem !important; }
  .m-xl-n-5 {
    margin: 3rem !important; }
  .mt-xl-n-5,
  .my-xl-n-5 {
    margin-top: 3rem !important; }
  .mr-xl-n-5,
  .mx-xl-n-5 {
    margin-right: 3rem !important; }
  .mb-xl-n-5,
  .my-xl-n-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-n-5,
  .mx-xl-n-5 {
    margin-left: 3rem !important; }
  .m-xl-n-4 {
    margin: 1.5rem !important; }
  .mt-xl-n-4,
  .my-xl-n-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-n-4,
  .mx-xl-n-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-n-4,
  .my-xl-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-n-4,
  .mx-xl-n-4 {
    margin-left: 1.5rem !important; }
  .m-xl-n-3 {
    margin: 1rem !important; }
  .mt-xl-n-3,
  .my-xl-n-3 {
    margin-top: 1rem !important; }
  .mr-xl-n-3,
  .mx-xl-n-3 {
    margin-right: 1rem !important; }
  .mb-xl-n-3,
  .my-xl-n-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-n-3,
  .mx-xl-n-3 {
    margin-left: 1rem !important; }
  .m-xl-n-2 {
    margin: 0.5rem !important; }
  .mt-xl-n-2,
  .my-xl-n-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-n-2,
  .mx-xl-n-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-n-2,
  .my-xl-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-n-2,
  .mx-xl-n-2 {
    margin-left: 0.5rem !important; }
  .m-xl-n-1 {
    margin: 0.25rem !important; }
  .mt-xl-n-1,
  .my-xl-n-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-n-1,
  .mx-xl-n-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-n-1,
  .my-xl-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-n-1,
  .mx-xl-n-1 {
    margin-left: 0.25rem !important; }
  .m-xl-n6 {
    margin: -4rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important; }
  .m-xl-n7 {
    margin: -5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #60baeb !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1c9ce2 !important; }

.text-secondary {
  color: #365c6c !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #1d3139 !important; }

.text-success {
  color: #52c41a !important; }

a.text-success:hover, a.text-success:focus {
  color: #368011 !important; }

.text-info {
  color: #13c2c2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0c7c7c !important; }

.text-warning {
  color: #fce359 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #fbd60e !important; }

.text-danger {
  color: #ff4d4f !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff0103 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-gray {
  color: #ced4da !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #a2aeb9 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/**
 * Basic styles for placeholders
 */
::-webkit-input-placeholder {
  color: #ccc !important; }
::-moz-placeholder {
  color: #ccc !important; }
::-ms-input-placeholder {
  color: #ccc !important; }
::placeholder {
  color: #ccc !important; }

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  background-color: #ffffff; }

/**
 * Set up the screen as the minimum height
 */
html,
body,
#app {
  min-height: 100vh; }

#beta:after {
  content: 'beta';
  position: fixed;
  width: 80px;
  height: 19px;
  background: #fce359;
  top: 5px;
  left: -24px;
  text-align: center;
  font-size: 11px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #365c6c;
  line-height: 22px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  z-index: 99; }

.no-beta ~ button > #beta {
  display: none; }

main {
  position: relative;
  min-height: 100vh;
  height: auto !important; }
  main.disabled:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * Basic styles for link
 */
a {
  text-decoration: none !important; }

/**
 * Basic styles for paragraph
 */
p {
  color: #77838f;
  line-height: 1.7; }

bloquote {
  line-height: 1.5; }

pre {
  font-family: "Raleway", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

.modal pre {
  margin-top: 0.5em;
  margin-bottom: 1em;
  padding: 0.8em 0.6em;
  width: auto;
  max-height: 600px;
  overflow: auto;
  font-family: Consolas, sans-serif !important;
  font-size: 13px;
  background-color: #dee2e6;
  border-radius: 3px; }

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local("Raleway Light"), local("Raleway-Light"), url(/fonts/raleway-light.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local("Raleway Light"), local("Raleway-Light"), url(/fonts/raleway-light2.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(/fonts/raleway-regular.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(/fonts/raleway-regular2.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local("Raleway Medium"), local("Raleway-Medium"), url(/fonts/raleway-medium.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local("Raleway Medium"), local("Raleway-Medium"), url(/fonts/raleway-medium2.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local("Raleway SemiBold"), local("Raleway-SemiBold"), url(/fonts/raleway-semibold.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local("Raleway SemiBold"), local("Raleway-SemiBold"), url(/fonts/raleway-semibold2.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/**
 * Basic typography style for copy text
 */
body {
  font: normal 100%/1.4 "Raleway", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap; }

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.alert-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  visibility: hidden;
  z-index: 1049; }
  .alert-wrapper > .alert {
    align-items: center;
    visibility: visible;
    display: flex;
    padding-bottom: 1px;
    padding-left: 1rem;
    padding-top: 2px;
    font-size: 0.9rem;
    line-height: 34px; }
    .alert-wrapper > .alert.alert-dismissible {
      padding-right: 3rem; }
    .alert-wrapper > .alert > .close {
      padding: 0.4rem 1rem; }
    .alert-wrapper > .alert .display-progress {
      align-items: center;
      display: flex;
      margin-left: 1rem;
      max-width: 40px;
      min-width: 40px;
      text-align: right;
      width: 40px; }
    .alert-wrapper > .alert > .progress {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      background-color: transparent; }
      .alert-wrapper > .alert > .progress > .progress-bar {
        overflow: hidden;
        display: flex; }
        .alert-wrapper > .alert > .progress > .progress-bar > span {
          align-items: center;
          text-align: left;
          margin: 0 0 0 1rem;
          font-size: 0.9rem;
          display: flex; }
          .alert-wrapper > .alert > .progress > .progress-bar > span > span:first-child {
            flex-grow: 1; }
          .alert-wrapper > .alert > .progress > .progress-bar > span > .btn {
            margin-right: 1.25rem !important; }

[data-mobile='true'] .alert-wrapper {
  top: 56px; }

@media (min-width: 576px) {
  header {
    height: 70px; }
    header .navbar-nav {
      width: 100%;
      justify-content: flex-end; }
      header .navbar-nav .nav-item {
        display: flex;
        align-items: center; }
      header .navbar-nav .dropdown {
        width: 100%;
        justify-content: flex-end; }
        header .navbar-nav .dropdown .nav-link[aria-expanded='true'] img {
          box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.3); } }

footer {
  padding-bottom: 80px; }
  footer .container {
    max-width: 930px;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto; }

@media (min-width: 576px) {
  footer .container {
    max-width: 510px; } }

@media (min-width: 768px) {
  footer {
    padding-bottom: 0; }
    footer .container {
      max-width: 690px; } }

@media (min-width: 992px) {
  footer .container {
    max-width: 930px; } }

@media (min-width: 1200px) {
  footer .container {
    max-width: 1110px; } }

.sidebar-wrapper .btn {
  box-shadow: none !important; }
  .sidebar-wrapper .btn.icon {
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center; }
  .sidebar-wrapper .btn i {
    font-size: 1.5rem; }

.sidebar-wrapper .sidebar-toggler {
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  z-index: 10; }
  .sidebar-wrapper .sidebar-toggler.position-fixed {
    top: 1000%; }
  .sidebar-wrapper .sidebar-toggler:after {
    content: '';
    position: fixed;
    top: 1000%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    -webkit-transition: top 0s ease 0.35s, opacity 0.35s ease;
    transition: top 0s ease 0.35s, opacity 0.35s ease; }

.sidebar-wrapper .navbar-collapse {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  z-index: 11;
  -webkit-transform: translate(-100vw);
          transform: translate(-100vw); }
  .sidebar-wrapper .navbar-collapse.show {
    -webkit-transform: translate(0);
            transform: translate(0); }
    .sidebar-wrapper .navbar-collapse.show ~ .sidebar-toggler {
      cursor: default; }
      .sidebar-wrapper .navbar-collapse.show ~ .sidebar-toggler:after {
        top: 0;
        opacity: 1;
        -webkit-transition: top 0s ease 0s, opacity 0.35s ease;
        transition: top 0s ease 0s, opacity 0.35s ease; }

.sidebar-wrapper .sidebar.sidebar-main {
  width: 64px;
  z-index: 3; }

.sidebar-wrapper .sidebar.sidebar-menu {
  width: 240px;
  z-index: 2; }
  .sidebar-wrapper .sidebar.sidebar-menu .btn {
    text-align: left;
    margin: 0 0 0.2rem;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    white-space: normal;
    font-size: 0.9rem;
    line-height: 34px; }
    .sidebar-wrapper .sidebar.sidebar-menu .btn i {
      font-size: 1.2rem;
      margin-right: 1rem; }
    .sidebar-wrapper .sidebar.sidebar-menu .btn span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.sidebar-wrapper .sidebar.sidebar-creation, .sidebar-wrapper .sidebar.sidebar-search {
  width: 100vw;
  max-width: 600px; }
  .sidebar-wrapper .sidebar.sidebar-creation .navbar-brand, .sidebar-wrapper .sidebar.sidebar-search .navbar-brand {
    align-self: flex-start;
    width: 64px;
    text-align: center; }

.sidebar-wrapper .sidebar .nav {
  height: 100vh; }
  .sidebar-wrapper .sidebar .nav .navbar-brand {
    margin: 0; }
  .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-toggle {
    padding: 0 0.5rem;
    position: relative; }
    .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-toggle:after {
      position: absolute;
      bottom: 0; }
    .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-toggle img {
      box-shadow: none !important; }
  .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-menu {
    padding: 0.5rem;
    font-size: 0.8rem; }
    .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-menu > div {
      display: flex; }
    .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-menu .dropdown-header {
      width: 100%; }
    .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-menu .dropdown-item {
      align-items: center;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.25rem;
      width: 100px; }
      .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-menu .dropdown-item:active {
        background-color: #f8f9fa;
        color: #212529; }
      .sidebar-wrapper .sidebar .nav .dropdown.apps .dropdown-menu .dropdown-item img {
        margin: 0.25rem auto; }
  .sidebar-wrapper .sidebar .nav .dropdown .dropdown-menu {
    font-size: 0.9rem; }
  .sidebar-wrapper .sidebar .nav .dropdown .nav-link[aria-expanded='true'] img {
    box-shadow: 0 0 0 2px #fff, 0 0 0 0.3rem #60baeb; }

.sidebar-wrapper ~ .container-wrapper {
  padding: 1rem; }

@media (min-width: 768px) {
  .sidebar-wrapper [toggler='#SidebarToggler'] {
    -webkit-transform: translate(0);
            transform: translate(0); }
  .sidebar-wrapper ~ .container-wrapper {
    margin-left: 64px; }
  .sidebar-wrapper.sidebar-wide ~ .container-wrapper {
    margin-left: 304px; } }

.btn {
  white-space: nowrap; }

.drop-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 1rem;
  z-index: 1009;
  visibility: hidden; }
  .drop-wrapper > .bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .drop-wrapper > .alert {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 1rem 1px;
    font-size: 0.9rem;
    line-height: 34px;
    -webkit-transform: translate(0, -200px);
            transform: translate(0, -200px);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    z-index: 1; }
  .drop-wrapper.show {
    visibility: visible; }
    .drop-wrapper.show > .bg {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .drop-wrapper.show > .alert {
      -webkit-transform: translate(0, 0px);
              transform: translate(0, 0px); }

.overwrite-modal .overwrite-preview {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem 0;
  white-space: nowrap; }
  .overwrite-modal .overwrite-preview:first-child {
    margin: 0;
    padding-top: 0; }
  .overwrite-modal .overwrite-preview:last-child {
    padding-bottom: 0; }
  .overwrite-modal .overwrite-preview > div {
    width: 100%; }
    .overwrite-modal .overwrite-preview > div:first-child {
      border: 1px solid transparent;
      display: flex;
      flex-grow: 1;
      margin-bottom: 0.5rem; }
      .overwrite-modal .overwrite-preview > div:first-child > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center; }
    .overwrite-modal .overwrite-preview > div:last-child {
      align-items: center;
      display: flex; }
      .overwrite-modal .overwrite-preview > div:last-child input {
        margin: 0.1rem 0.5rem 0.2rem; }
      .overwrite-modal .overwrite-preview > div:last-child label {
        margin: 0; }
  .overwrite-modal .overwrite-preview .old-file,
  .overwrite-modal .overwrite-preview .new-file {
    border: 1px solid #dee2e6;
    flex-grow: 1;
    padding: 1rem;
    width: 0; }
  .overwrite-modal .overwrite-preview .old-file {
    margin-right: 1rem; }
  .overwrite-modal .overwrite-preview .delete-x {
    align-self: center;
    color: #ff4d4f;
    display: none;
    font-size: 5rem;
    padding-bottom: calc(22px + 0.5rem);
    position: absolute;
    text-shadow: 0 0 2rem #fff;
    z-index: 1; }
  .overwrite-modal .overwrite-preview .preview {
    overflow: visible; }
    .overwrite-modal .overwrite-preview .preview .preview-body {
      height: 200px; }
      .overwrite-modal .overwrite-preview .preview .preview-body .img {
        height: 100%;
        -webkit-transform: none !important;
                transform: none !important; }
      .overwrite-modal .overwrite-preview .preview .preview-body audio,
      .overwrite-modal .overwrite-preview .preview .preview-body video {
        width: 100%; }
    .overwrite-modal .overwrite-preview .preview .preview-footer {
      flex-direction: column; }
      .overwrite-modal .overwrite-preview .preview .preview-footer span {
        margin: 0 0 0.5rem; }
  .overwrite-modal .overwrite-preview span {
    display: block;
    margin-top: 0.5rem;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .overwrite-modal .overwrite-preview span.keep-both-name, .overwrite-modal .overwrite-preview span.overwrite-name {
      display: none;
      overflow: hidden;
      text-overflow: ellipsis; }
  .overwrite-modal .overwrite-preview[data-overwrite='true'] > div:first-child {
    border-color: #dee2e6; }
    .overwrite-modal .overwrite-preview[data-overwrite='true'] > div:first-child > div {
      border-color: transparent; }
  .overwrite-modal .overwrite-preview[data-overwrite='true'] .old-file > span {
    visibility: hidden; }
  .overwrite-modal .overwrite-preview[data-overwrite='true'] .old-file .preview {
    opacity: 0.4; }
  .overwrite-modal .overwrite-preview[data-overwrite='true'] .delete-x,
  .overwrite-modal .overwrite-preview[data-overwrite='true'] .overwrite-name {
    display: block;
    margin: 0.5rem 0 0 calc(-100% - 3rem);
    max-width: 430px; }
  .overwrite-modal .overwrite-preview[data-overwrite='false'] .keep-both-name {
    display: block; }

.eye {
  font-size: 1.5rem;
  padding: 0 0.5rem 0 0;
  position: absolute;
  right: 0;
  bottom: 0; }
  .eye[data-type='text'] .mdi-eye {
    display: block !important; }
  .eye[data-type='text'] .mdi-eye-off {
    display: none; }

.preloader {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  z-index: 1001;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

main > .preloader,
.container > .preloader {
  position: fixed; }

.preloader ~ * .preloader {
  display: none !important; }

.placeholder {
  width: 100%;
  background: #ced4da;
  opacity: 0.65; }
  .placeholder.placeholder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 3px;
    color: rgba(96, 186, 235, 0.3); }
    .placeholder.placeholder-image:before {
      position: absolute; }
    .placeholder.placeholder-image:after {
      content: '';
      display: block;
      padding-top: 60%; }
  .placeholder.placeholder-text {
    display: block;
    width: 80%;
    height: 1.2rem;
    border-radius: 50rem; }
    .placeholder.placeholder-text.sm-placeholder {
      height: 0.9rem; }
    .placeholder.placeholder-text.lg-placeholder {
      height: 2rem; }

.placeholder-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e9ecef; }
  .placeholder-fullscreen .mdi {
    font-size: 8rem; }

.preview-modal {
  height: 100vh;
  margin: auto;
  max-width: 100vw;
  width: 100vw; }
  .preview-modal .powered {
    bottom: 100px;
    margin: 0.5rem 1rem;
    position: fixed;
    z-index: 9; }
    .preview-modal .powered small {
      color: rgba(108, 117, 125, 0.3);
      font-size: 10px; }
    .preview-modal .powered img {
      display: block;
      -webkit-filter: brightness(0) opacity(0.2);
              filter: brightness(0) opacity(0.2);
      width: 80px; }
  .preview-modal .modal-content {
    border: none;
    border-radius: 0;
    height: 100vh;
    margin: auto;
    width: 100vw; }
    .preview-modal .modal-content .modal-header {
      align-items: center;
      height: 56px; }
      .preview-modal .modal-content .modal-header .close {
        color: #60baeb;
        margin: -1rem 0 -1rem -1rem;
        opacity: 1;
        order: 1; }
        .preview-modal .modal-content .modal-header .close:before {
          content: '\F004D';
          font: normal normal normal 24px/1 'Material Design Icons'; }
        .preview-modal .modal-content .modal-header .close:hover {
          color: #1a8dcb; }
        .preview-modal .modal-content .modal-header .close span {
          display: none; }
      .preview-modal .modal-content .modal-header .modal-title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 0 0;
        order: 2;
        width: 100%;
        white-space: nowrap; }
        .preview-modal .modal-content .modal-header .modal-title > span {
          flex-grow: 1;
          margin-right: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 0;
          white-space: nowrap; }
        .preview-modal .modal-content .modal-header .modal-title #ActionsHeader {
          margin: 0 -0.8rem 0 0;
          position: relative; }
          .preview-modal .modal-content .modal-header .modal-title #ActionsHeader i {
            color: #60baeb; }
    .preview-modal .modal-content .modal-body {
      display: flex;
      flex-direction: column;
      padding: 0; }

.preview {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 120px;
  width: 100%; }
  .preview .preview-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    width: 100%; }
    .preview .preview-body[data-type='text'] {
      height: 0;
      padding: 0; }
    .preview .preview-body textarea,
    .preview .preview-body [contentEditable] {
      border: none;
      font-family: monospace, Consolas, sans-serif !important;
      min-height: 100%;
      min-width: 100%;
      outline: none;
      white-space: pre;
      resize: none;
      padding: 1rem; }
  .preview .preview-footer {
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    width: 100%; }
    .preview .preview-footer > div {
      display: flex;
      flex-direction: column;
      margin: 0 1rem; }
  .preview .img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    margin: 1rem;
    width: calc(100% - 2rem); }
  .preview audio,
  .preview video {
    align-items: center;
    display: flex;
    max-height: 100%;
    outline: none;
    position: absolute;
    width: calc(100% - 6rem); }
  .preview audio {
    max-width: 500px; }
  .preview video {
    padding: 1rem; }
  .preview iframe {
    border: none;
    height: 100%;
    width: 100%; }
  .preview .btn {
    align-items: center;
    color: rgba(173, 181, 189, 0.3);
    display: flex;
    font-size: 3rem;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0; }
    .preview .btn:hover {
      color: #748290; }
    .preview .btn.btn-prev {
      left: 0;
      width: 20%; }
    .preview .btn.btn-next {
      justify-content: flex-end;
      right: 0;
      width: 80%; }

.progress {
  position: relative;
  height: 4px;
  min-height: 4px;
  display: block;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 2px;
  background-clip: padding-box;
  overflow: hidden;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  will-change: height, transform;
  z-index: 999; }
  .progress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: #60baeb;
    -webkit-transition: width 0.3s linear;
    transition: width 0.3s linear; }
  .progress .indeterminate {
    background-color: #60baeb;
    height: 100%;
    visibility: hidden; }
    .progress .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      visibility: visible; }
    .progress .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s;
      visibility: visible; }
  .progress.show,
  .progress .indeterminate:before,
  .progress .indeterminate:after {
    -webkit-transform: scale(1);
            transform: scale(1); }

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

.search-box {
  max-width: 720px; }

[data-mobile='true'] .search-box {
  z-index: 0 !important; }

.tree.expanded > .menu-button i {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease; }

.preview-wrapper {
  align-items: flex-end;
  top: 0;
  display: flex;
  height: 100vh;
  left: 0;
  position: fixed;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  will-change: transform;
  width: 100vw;
  z-index: 9999; }
  .preview-wrapper div {
    position: relative; }
  .preview-wrapper > div {
    background-color: #f8f9fa;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%; }
    .preview-wrapper > div > .content {
      bottom: 0;
      box-sizing: inherit;
      left: 0;
      overflow: hidden;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      will-change: width, padding-top, transform; }
      .preview-wrapper > div > .content.drawer-opened .drawer {
        -webkit-animation-name: drawerIn;
                animation-name: drawerIn;
        -webkit-animation-duration: 0.3s;
                animation-duration: 0.3s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        .preview-wrapper > div > .content.drawer-opened .drawer .drawer-menu {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
        .preview-wrapper > div > .content.drawer-opened .drawer .drawer-bg {
          opacity: 1; }
  .preview-wrapper .action-bar,
  .preview-wrapper .pager li {
    height: 3rem;
    max-height: 3rem;
    min-height: 3rem; }
  .preview-wrapper .action-bar {
    display: flex;
    font-weight: bold;
    height: 3rem;
    line-height: 3rem;
    max-height: 3rem;
    min-height: 3rem;
    padding: 0 1rem; }
    .preview-wrapper .action-bar .icon {
      cursor: pointer;
      font-size: 1.5rem;
      margin-right: 1.5rem; }
  .preview-wrapper .dialog {
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 1rem;
    position: absolute;
    width: 100%;
    z-index: 2; }
    .preview-wrapper .dialog > .card {
      border: none;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      max-height: 100%;
      width: 100%; }
      .preview-wrapper .dialog > .card .card-title {
        font-weight: bold; }
      .preview-wrapper .dialog > .card .card-footer {
        background-color: transparent;
        border: none;
        text-align: right; }
        .preview-wrapper .dialog > .card .card-footer button {
          color: #000000;
          font-size: 0.9rem;
          font-weight: bold;
          text-transform: uppercase; }
          .preview-wrapper .dialog > .card .card-footer button:hover {
            color: #60baeb; }
  .preview-wrapper .drawer {
    -webkit-animation-name: drawerOut;
            animation-name: drawerOut;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    z-index: 9; }
    .preview-wrapper .drawer .drawer-menu {
      background: #ffffff;
      height: 100%;
      width: 75%;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      z-index: 1; }
      .preview-wrapper .drawer .drawer-menu .subtitle,
      .preview-wrapper .drawer .drawer-menu .title {
        font-size: 0.8rem;
        font-weight: 500; }
      .preview-wrapper .drawer .drawer-menu .subtitle {
        display: block;
        opacity: 0.7; }
      .preview-wrapper .drawer .drawer-menu .title {
        display: block; }
      .preview-wrapper .drawer .drawer-menu header {
        height: auto;
        padding: 1rem; }
        .preview-wrapper .drawer .drawer-menu header:empty {
          display: none; }
        .preview-wrapper .drawer .drawer-menu header .icon {
          width: 50px;
          display: block;
          margin-bottom: 0.5rem; }
      .preview-wrapper .drawer .drawer-menu .drawer-content > .items > .item {
        border-bottom: 1px solid #dddddd;
        padding: 1rem !important; }
        .preview-wrapper .drawer .drawer-menu .drawer-content > .items > .item:last-child {
          border-bottom: none; }
        .preview-wrapper .drawer .drawer-menu .drawer-content > .items > .item > .icon {
          margin: 0 1.2rem 0 0; }
        .preview-wrapper .drawer .drawer-menu .drawer-content > .items > .item > .title {
          opacity: 0.7; }
        .preview-wrapper .drawer .drawer-menu .drawer-content > .items > .item .items {
          width: 100%; }
      .preview-wrapper .drawer .drawer-menu .drawer-content .item {
        align-items: center;
        display: flex;
        font-size: 0.8rem;
        flex-wrap: wrap;
        padding: 1rem 0 0 1.2rem; }
        .preview-wrapper .drawer .drawer-menu .drawer-content .item .icon {
          width: 1.2rem;
          margin: 0 1.2rem 0 -1.2rem; }
    .preview-wrapper .drawer .drawer-bg {
      background-color: rgba(0, 0, 0, 0.6);
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      z-index: 0; }
  .preview-wrapper .pager {
    overflow-x: auto;
    overflow-y: hidden; }
    .preview-wrapper .pager[data-tabs-position='bottom'] {
      order: 2; }
    .preview-wrapper .pager[data-tab-style='icon'] li {
      height: 3rem;
      max-height: 3rem;
      min-height: 3rem; }
      .preview-wrapper .pager[data-tab-style='icon'] li span {
        display: none; }
    .preview-wrapper .pager[data-tab-style='icon_title'] ul.pager-tabs li {
      height: 3.75rem;
      line-height: 1.2rem;
      max-height: 3.75rem;
      min-height: 3.75rem;
      width: 100%; }
      .preview-wrapper .pager[data-tab-style='icon_title'] ul.pager-tabs li span {
        font-size: 0.7rem;
        font-weight: bold; }
    .preview-wrapper .pager[data-tab-style='title'] .icon {
      display: none; }
    .preview-wrapper .pager .pager-index {
      bottom: 0;
      height: 3px;
      position: absolute;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      z-index: 0; }
    .preview-wrapper .pager ul.pager-tabs {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0; }
      .preview-wrapper .pager ul.pager-tabs > li {
        flex-grow: 1;
        line-height: 3rem;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1; }
        .preview-wrapper .pager ul.pager-tabs > li .icon {
          background-color: currentColor;
          height: 26px;
          -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
          -webkit-mask-position: center;
                  mask-position: center;
          -webkit-mask-size: 26px;
                  mask-size: 26px;
          width: 100%; }
  .preview-wrapper .main-content {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-scroll-snap-type: x mandatory;
        -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory; }
    .preview-wrapper .main-content > .component-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 100%;
      min-width: 100%;
      scroll-snap-align: center;
      width: 100%; }
      .preview-wrapper .main-content > .component-wrapper > .visor {
        width: 100%;
        height: 100%;
        overflow-y: auto; }
  .preview-wrapper .hover {
    box-shadow: 0 0 0 1px #60baeb, 0 0 4px 2px #60baeb;
    opacity: 0.9;
    z-index: 2; }
    .preview-wrapper .hover:hover {
      box-shadow: none;
      opacity: 1 !important; }
    .preview-wrapper .hover ~ div {
      z-index: 2; }
  .preview-wrapper .no-google-services {
    display: block;
    padding: 0.25rem;
    z-index: 3; }

.visor {
  display: flex;
  flex-direction: row;
  overflow: hidden; }
  .visor > div {
    display: flex; }
  .visor .component-wrapper[data-width='100%'] {
    width: -webkit-fill-available !important; }
  .visor .component-wrapper[data-height='fill'], .visor .component-wrapper[data-height*='%'] {
    height: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important; }
  .visor .component-wrapper[data-height='wrap'],
  .visor .component-wrapper[data-height='wrap'] > .visor {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .visor iframe {
    border: none;
    display: block; }
  .visor[data-direction='column'] {
    flex-wrap: wrap; }
    .visor[data-direction='column'] > div {
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important; }
      .visor[data-direction='column'] > div[data-position='absolute'] > div {
        position: relative !important; }
  .visor[data-direction='row'] > div > .component-wrapper {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important; }
  .visor > div[data-position='absolute'] {
    position: absolute;
    top: 0;
    left: 0; }
    .visor > div[data-position='absolute'][data-halign='center'] {
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); }
      .visor > div[data-position='absolute'][data-halign='center'][data-valign='center'] {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .visor > div[data-position='absolute'][data-halign='end'] {
      left: unset;
      right: 0; }
    .visor > div[data-position='absolute'][data-valign='center'] {
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
    .visor > div[data-position='absolute'][data-valign='bottom'] {
      top: unset;
      bottom: 0; }
  .visor > div[data-halign='center'] {
    justify-content: center; }
  .visor > div[data-halign='end'] {
    justify-content: flex-end; }
  .visor > div[data-valign='center'] {
    align-items: center; }
  .visor > div[data-valign='bottom'] {
    align-items: flex-end; }
  .visor > div > .component-wrapper {
    overflow: hidden;
    background-position: center;
    background-size: cover; }
    .visor > div > .component-wrapper[data-type='banner'] {
      align-items: center !important;
      display: flex;
      justify-content: center !important;
      width: 100%; }
      .visor > div > .component-wrapper[data-type='banner']:before {
        content: '';
        padding-top: 15%;
        display: block; }
      .visor > div > .component-wrapper[data-type='banner'] > img {
        position: absolute;
        width: 100%; }
    .visor > div > .component-wrapper[data-type='button'] > div {
      align-items: center;
      border-radius: 4rem;
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
      display: flex;
      height: 4rem;
      justify-content: center;
      padding: 0.75rem;
      width: 4rem; }
      .visor > div > .component-wrapper[data-type='button'] > div > div {
        background-position: center;
        background-size: contain;
        height: 75%;
        width: 75%; }
    .visor > div > .component-wrapper[data-type='canvas'] canvas {
      display: block;
      height: 100%;
      width: 100%; }
    .visor > div > .component-wrapper[data-type='cropper'] {
      align-items: center;
      display: flex;
      justify-content: center; }
      .visor > div > .component-wrapper[data-type='cropper'] .cropper {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper .img {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper .img:before {
            content: '';
            display: block;
            padding-top: 66.66%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='16:9'] .frame-wrapper {
          width: 80%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='16:9'] .frame-wrapper:before {
            padding-top: 56.25%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='9:16'] .frame-wrapper {
          width: 30%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='9:16'] .frame-wrapper:before {
            padding-top: 177.78%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='4:3'] .frame-wrapper {
          width: 70%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='4:3'] .frame-wrapper:before {
            padding-top: 75%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='3:4'] .frame-wrapper:before {
          padding-top: 133.33%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='free'] .frame-wrapper:before {
          padding-top: 50%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='fit'] .frame-wrapper {
          height: 100%;
          width: 100%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='fit'] .frame-wrapper:before {
            display: none; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='square'] .frame-wrapper:before {
          padding-top: 100%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='circle'] .frame-wrapper, .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='circle_square'] .frame-wrapper {
          border-radius: 10rem; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='circle'] .frame-wrapper:before, .visor > div > .component-wrapper[data-type='cropper'] .cropper[data-mode='circle_square'] .frame-wrapper:before {
            padding-top: 100%; }
        .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper {
          align-items: center;
          color: #00000096;
          box-shadow: 0 0 0 100rem;
          display: flex;
          justify-content: center;
          position: absolute;
          width: 40%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame {
            color: #1c9ce2;
            border: 1px solid;
            height: 100%;
            position: absolute;
            width: 100%; }
            .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides,
            .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .handlers {
              position: absolute;
              width: 100%;
              height: 100%; }
            .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides {
              color: #60baeb;
              position: absolute; }
              .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div {
                position: absolute; }
                .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(1), .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(2) {
                  height: 100%;
                  border-left: 1px solid; }
                .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(3), .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(4) {
                  width: 100%;
                  border-top: 1px solid; }
                .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(1) {
                  left: 33.33%; }
                .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(2) {
                  left: 66.66%; }
                .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(3) {
                  top: 33.33%; }
                .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .frame .guides > div:nth-child(4) {
                  top: 66.66%; }
          .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .handlers {
            color: #1c9ce2;
            height: 100%;
            position: absolute;
            width: 100%; }
            .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .handlers > div {
              background-color: currentColor;
              border-radius: 2rem;
              height: 2rem;
              position: absolute;
              width: 2rem; }
              .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .handlers > div:nth-child(1) {
                left: -1.1rem;
                top: -1.1rem; }
              .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .handlers > div:nth-child(2) {
                right: -1.1rem;
                top: -1.1rem; }
              .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .handlers > div:nth-child(3) {
                bottom: -1.1rem;
                right: -1.1rem; }
              .visor > div > .component-wrapper[data-type='cropper'] .cropper .frame-wrapper .handlers > div:nth-child(4) {
                bottom: -1.1rem;
                left: -1.1rem; }
    .visor > div > .component-wrapper[data-type='group'] {
      display: flex; }
      .visor > div > .component-wrapper[data-type='group'][data-direction='column'] {
        flex-direction: column; }
      .visor > div > .component-wrapper[data-type='group'] > .visor {
        flex-grow: 1;
        height: 100%;
        width: 100%; }
        .visor > div > .component-wrapper[data-type='group'] > .visor[data-scroll='true'][data-direction='column'] {
          overflow-y: auto; }
        .visor > div > .component-wrapper[data-type='group'] > .visor[data-scroll='true'][data-direction='row'] {
          overflow-x: auto; }
    .visor > div > .component-wrapper[data-type='list'] {
      display: flex; }
      .visor > div > .component-wrapper[data-type='list'][data-direction='column'] {
        flex-direction: column; }
    .visor > div > .component-wrapper[data-type='image'] > div {
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%; }
      .visor > div > .component-wrapper[data-type='image'] > div[data-scale][data-scale='crop'] {
        background-size: cover; }
        .visor > div > .component-wrapper[data-type='image'] > div[data-scale][data-scale='crop'] img {
          display: none; }
    .visor > div > .component-wrapper[data-type='image'][data-height='100%'][data-width='100%'] {
      display: flex;
      align-items: center;
      justify-content: center; }
      .visor > div > .component-wrapper[data-type='image'][data-height='100%'][data-width='100%'] img {
        height: 100%;
        object-fit: fill;
        position: absolute;
        width: 100%; }
    .visor > div > .component-wrapper[data-type='image'] img {
      height: 100%;
      object-fit: cover;
      width: 100%; }
    .visor > div > .component-wrapper[data-type='text'] > div {
      display: flex;
      height: 100%;
      width: 100%;
      word-break: break-word; }
    .visor > div > .component-wrapper[data-type='text'] [data-align='flex-start'] {
      justify-content: flex-start !important;
      text-align: left; }
    .visor > div > .component-wrapper[data-type='text'] [data-align='center'] {
      justify-content: center !important;
      text-align: center; }
    .visor > div > .component-wrapper[data-type='text'] [data-align='flex-end'] {
      justify-content: flex-end !important;
      text-align: right; }
    .visor > div > .component-wrapper[data-type='text'] [data-valign='top'] {
      align-items: flex-start !important; }
    .visor > div > .component-wrapper[data-type='text'] [data-valign='middle'] {
      align-items: center !important; }
    .visor > div > .component-wrapper[data-type='text'] [data-valign='bottom'] {
      align-items: flex-end !important; }
    .visor > div > .component-wrapper[data-type='inputtext'] {
      padding: 1px; }
      .visor > div > .component-wrapper[data-type='inputtext'] > input {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #50879f;
        outline: none;
        width: 100%; }
        .visor > div > .component-wrapper[data-type='inputtext'] > input:focus {
          border-color: #60baeb; }
    .visor > div > .component-wrapper[data-type='map'] iframe, .visor > div > .component-wrapper[data-type='pdfviewer'] iframe, .visor > div > .component-wrapper[data-type='web'] iframe {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%; }
    .visor > div > .component-wrapper[data-type='player'] {
      display: flex;
      flex-direction: column; }
      .visor > div > .component-wrapper[data-type='player'] > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
        width: 100%; }
      .visor > div > .component-wrapper[data-type='player'] audio {
        outline: none;
        width: 100%; }
    .visor > div > .component-wrapper[data-type='map'] {
      height: 300px;
      width: 100%; }
      .visor > div > .component-wrapper[data-type='map'] .no-apikey {
        display: block;
        padding: 0.25rem; }
    .visor > div > .component-wrapper[data-type='range'] {
      align-items: center;
      display: flex; }
      .visor > div > .component-wrapper[data-type='range'] .noUi-target {
        background: rgba(0, 0, 0, 0.15);
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin: 0.5rem 1rem; }
        .visor > div > .component-wrapper[data-type='range'] .noUi-target.noUi-horizontal {
          height: 4px;
          width: 100%; }
          .visor > div > .component-wrapper[data-type='range'] .noUi-target.noUi-horizontal .noUi-origin {
            align-items: center;
            height: 4px; }
        .visor > div > .component-wrapper[data-type='range'] .noUi-target.noUi-vertical {
          height: 100%;
          width: 4px; }
          .visor > div > .component-wrapper[data-type='range'] .noUi-target.noUi-vertical .noUi-origin {
            justify-items: center;
            width: 4px; }
        .visor > div > .component-wrapper[data-type='range'] .noUi-target .noUi-base .noUi-connects {
          border-radius: 0; }
        .visor > div > .component-wrapper[data-type='range'] .noUi-target .noUi-base .noUi-origin {
          display: flex; }
      .visor > div > .component-wrapper[data-type='range'] .noUi-handle {
        background-color: #fff;
        border: none;
        border-radius: 100rem;
        box-shadow: none;
        cursor: pointer;
        height: 0.8rem;
        min-height: 0rem;
        min-width: 0rem;
        outline: none;
        position: absolute;
        right: -0.3rem;
        top: unset;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        width: 0.8rem; }
        .visor > div > .component-wrapper[data-type='range'] .noUi-handle:before, .visor > div > .component-wrapper[data-type='range'] .noUi-handle:after {
          display: none; }
    .visor > div > .component-wrapper[data-type='scanner'] div {
      align-items: center;
      background-color: #1c9ce2;
      box-shadow: inset 0 0 6rem -2rem rgba(0, 0, 0, 0.8);
      display: flex;
      height: 100%;
      justify-content: center;
      -webkit-perspective: 500px;
              perspective: 500px;
      position: absolute;
      width: 100%; }
      .visor > div > .component-wrapper[data-type='scanner'] div canvas {
        background-color: #ffffff;
        padding: 5%;
        position: absolute;
        width: 90% !important;
        height: auto !important;
        max-height: 150%;
        -webkit-transform: rotate3d(1, 0, 0, 30deg);
                transform: rotate3d(1, 0, 0, 30deg); }
    .visor > div > .component-wrapper[data-type='select'] {
      overflow: visible; }
      .visor > div > .component-wrapper[data-type='select'] .dropdown .dropdown-toggle {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 0 !important;
        box-shadow: none !important;
        color: inherit;
        display: flex;
        justify-content: space-between;
        width: 100%; }
      .visor > div > .component-wrapper[data-type='select'] .dropdown .dropdown-menu {
        border: none;
        border-radius: 0;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35);
        color: inherit;
        margin: 0;
        padding: 0;
        width: 100%; }
        .visor > div > .component-wrapper[data-type='select'] .dropdown .dropdown-menu .dropdown-item {
          background-color: transparent !important;
          color: inherit !important; }
    .visor > div > .component-wrapper[data-type='switch'] > div {
      display: flex;
      align-items: center;
      justify-content: center; }
      .visor > div > .component-wrapper[data-type='switch'] > div input[type='checkbox'] {
        height: 0;
        width: 0;
        visibility: hidden; }
        .visor > div > .component-wrapper[data-type='switch'] > div input[type='checkbox']:checked + label:after {
          background-color: #60baeb;
          left: calc(100% - 1.4rem); }
        .visor > div > .component-wrapper[data-type='switch'] > div input[type='checkbox'] + label {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 1rem;
          margin: 0.5rem;
          position: relative;
          width: 2.5rem; }
          .visor > div > .component-wrapper[data-type='switch'] > div input[type='checkbox'] + label:before {
            background-color: #adb5bd;
            box-shadow: inset 0 0 0 1rem rgba(0, 0, 0, 0.2);
            border-radius: 1rem;
            content: '';
            height: 100%;
            -webkit-transition: background-color 0.3s ease;
            transition: background-color 0.3s ease;
            width: 100%;
            will-change: background-color; }
          .visor > div > .component-wrapper[data-type='switch'] > div input[type='checkbox'] + label:after {
            background-color: #fff;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0.15rem 0.15rem rgba(0, 0, 0, 0.25);
            border-radius: 2rem;
            content: '';
            height: 1.4rem;
            left: 0;
            min-height: 1.4rem;
            min-width: 1.4rem;
            position: absolute;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            width: 1.4rem;
            will-change: left, background-color; }
    .visor > div > .component-wrapper[data-type='video'] {
      display: flex;
      flex-direction: column; }
      .visor > div > .component-wrapper[data-type='video'] > div,
      .visor > div > .component-wrapper[data-type='video'] video {
        height: 100%;
        width: 100%; }
      .visor > div > .component-wrapper[data-type='video'][data-height='wrap'] video {
        height: auto; }
      .visor > div > .component-wrapper[data-type='video'] > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center; }
    .visor > div > .component-wrapper[data-type='youtube'] > div:before {
      content: '';
      display: block;
      padding-top: 56.25%; }
    .visor > div > .component-wrapper[data-type='youtube'] > div[data-api-key='']:before {
      padding-top: 80%; }
    .visor > div > .component-wrapper[data-type='youtube'] > div .dialog {
      top: 0; }
    .visor > div > .component-wrapper[data-type='youtube'] > div iframe {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; }

.home {
  padding-top: 70px; }
  .home .jumbotron {
    background-size: cover;
    background-position: center;
    overflow: hidden; }
    .home .jumbotron .banner-fluid {
      margin: -65px 0; }
  .home .marketing {
    font-size: 16px;
    color: #5a5a5a; }
    .home .marketing h2 {
      font-weight: 400; }
    .home .marketing .row .col-lg-4 {
      margin-bottom: 1.5rem;
      text-align: center; }
      .home .marketing .row .col-lg-4 p {
        margin-right: 0.75rem;
        margin-left: 0.75rem; }
    .home .marketing .row.featurette .featurette-divider {
      margin: 5rem 0; }
    .home .marketing .row.featurette .featurette-heading {
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: -0.05rem;
      color: #365c6c; }
    .home .marketing .row.featurette .lead {
      font-size: 1rem;
      color: #343a40; }
    .home .marketing .row.featurette img {
      width: 500px;
      height: auto; }

@media (min-width: 992px) {
  .featurette-heading {
    font-size: 2.5rem; } }

@media (min-width: 1200px) {
  .featurette-heading {
    margin-top: 7rem; } }

.directories .container-wrapper {
  height: calc(100vh - 56px);
  padding-bottom: 0;
  display: flex;
  flex-direction: column; }

.directories .directory-select {
  z-index: 2; }

.directories table {
  position: relative; }
  .directories table .custom-checkbox .custom-control-label {
    top: 50%;
    position: absolute; }
  .directories table thead th {
    border-bottom: none; }
  .directories table tr > * {
    overflow: hidden;
    text-overflow: ellipsis; }
    .directories table tr > *:first-child {
      max-width: 40px;
      min-width: 40px;
      position: relative;
      width: 40px; }
    .directories table tr > *:nth-child(2) {
      max-width: 0;
      min-width: 0;
      width: 100%; }
    .directories table tr > *:nth-child(3) {
      max-width: 80px;
      min-width: 0;
      width: 100%; }
    .directories table tr > *:nth-child(4) {
      max-width: 80px;
      min-width: 0;
      width: 100%; }
    .directories table tr > *:nth-child(5) {
      max-width: 150px;
      min-width: 150px;
      width: 150px; }
    .directories table tr > *:last-child {
      max-width: 50px;
      min-width: 50px;
      overflow: visible;
      width: 50px; }
  .directories table thead {
    width: 100%;
    background: #fff;
    z-index: 1; }
    .directories table thead tr th {
      font-size: 0.8rem; }
    .directories table thead tr th:nth-child(2) {
      padding-left: 3rem;
      width: 100%; }
  .directories table tbody tr .ant-checkbox {
    opacity: 0; }
  .directories table tbody tr:hover .ant-checkbox,
  .directories table tbody tr:hover .preview-button {
    opacity: 1 !important; }
  .directories table tbody tr[data-some-checked='true'] .ant-checkbox {
    opacity: 1 !important; }
  .directories table tbody tr.active i,
  .directories table tbody tr.active span {
    color: #60baeb !important; }
  .directories table tbody tr td:nth-child(2) span {
    color: #343a40; }
  .directories table tbody tr td:nth-child(2) a:hover span {
    color: #60baeb !important; }
  .directories table tbody tr td:last-child {
    min-width: 50px; }
  .directories table tbody .btn {
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-weight: inherit;
    padding: 0;
    text-align: left;
    white-space: nowrap; }
    .directories table tbody .btn span {
      overflow: hidden;
      text-overflow: ellipsis; }

.directories [data-view='list'] tbody:after {
  content: '';
  display: block;
  width: 100%;
  height: 100px; }

.directories [data-view='grid'] {
  display: flex;
  flex-direction: column !important; }
  .directories [data-view='grid'] [data-hide-on='grid'],
  .directories [data-view='grid'] [data-hide-on='grid,mobile'] {
    visibility: hidden !important; }
  .directories [data-view='grid'] tbody {
    display: flex;
    flex-wrap: wrap; }
    .directories [data-view='grid'] tbody [data-hide-on='grid'],
    .directories [data-view='grid'] tbody [data-hide-on='grid,mobile'] {
      display: none !important; }
    .directories [data-view='grid'] tbody tr {
      display: flex;
      justify-content: center;
      min-width: 200px;
      max-width: 200px;
      width: 200px;
      margin-top: 1rem;
      margin-right: 1rem;
      text-align: center;
      background-color: transparent !important; }
      .directories [data-view='grid'] tbody tr td {
        display: none;
        border: none; }
        .directories [data-view='grid'] tbody tr td:first-child {
          display: block;
          margin-right: -1.5rem; }
        .directories [data-view='grid'] tbody tr td:nth-child(2) {
          width: 100%;
          max-width: 100%;
          display: block; }
        .directories [data-view='grid'] tbody tr td .preview {
          align-items: center;
          display: flex;
          height: 90px;
          min-height: 0;
          width: 100% !important; }
          .directories [data-view='grid'] tbody tr td .preview i {
            font-size: 4rem !important; }
          .directories [data-view='grid'] tbody tr td .preview h6 {
            display: none; }
          .directories [data-view='grid'] tbody tr td .preview audio {
            width: 100%; }
          .directories [data-view='grid'] tbody tr td .preview i,
          .directories [data-view='grid'] tbody tr td .preview video {
            height: 100%;
            margin: 0 auto;
            width: auto !important; }
          .directories [data-view='grid'] tbody tr td .preview .btn {
            display: none !important; }
          .directories [data-view='grid'] tbody tr td .preview .preview-body {
            padding: 0 0 0.5rem; }
          .directories [data-view='grid'] tbody tr td .preview .preview-footer {
            display: none; }
        .directories [data-view='grid'] tbody tr td .d-flex {
          flex-direction: column;
          align-items: center; }
          .directories [data-view='grid'] tbody tr td .d-flex .placeholder-image {
            margin: 0 0 0.5rem !important;
            width: 100px !important; }
          .directories [data-view='grid'] tbody tr td .d-flex .placeholder-text {
            max-width: 100%; }
          .directories [data-view='grid'] tbody tr td .d-flex .mdi {
            width: 100px !important;
            margin: 0 !important;
            font-size: 4rem !important; }
        .directories [data-view='grid'] tbody tr td .btn {
          display: block !important;
          text-align: center; }
          .directories [data-view='grid'] tbody tr td .btn span {
            display: block;
            margin: 0 auto !important; }
      .directories [data-view='grid'] tbody tr[data-checked='true'] td {
        border-radius: 0 0.25rem 0.25rem 0; }
        .directories [data-view='grid'] tbody tr[data-checked='true'] td:first-child {
          border-radius: 0.25rem 0 0 0.25rem; }

.directories [data-unlistened='true'] {
  font-weight: bold; }

.directories .file-player-wrapper {
  margin-top: -72px; }
  .directories .file-player-wrapper .wave-wrapper {
    width: 100% !important;
    margin-bottom: -50px; }
  .directories .file-player-wrapper .preloader {
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(54, 92, 108, 0.25)));
    background: -webkit-linear-gradient(transparent, rgba(54, 92, 108, 0.25));
    background: linear-gradient(transparent, rgba(54, 92, 108, 0.25));
    padding: 90px 0 40px; }

.share-file .check {
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: #365c6c;
  color: #fff;
  font-size: 26px; }

.share-file .copied {
  position: relative; }
  .share-file .copied .check {
    opacity: 0;
    display: flex;
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

#ActionsHeader {
  display: flex; }
  #ActionsHeader i {
    font-size: 1.5rem; }
  #ActionsHeader .dropdown-menu {
    -webkit-transform: none !important;
            transform: none !important; }
    #ActionsHeader .dropdown-menu .dropdown-item {
      background-color: #ffffff; }

[data-mobile='false'] #ActionsHeader {
  margin: 0 1rem;
  position: relative; }
  [data-mobile='false'] #ActionsHeader > a {
    display: none; }
  [data-mobile='false'] #ActionsHeader i {
    color: #6c757d !important; }
  [data-mobile='false'] #ActionsHeader .dropdown-menu {
    border: none;
    display: flex;
    position: relative !important;
    padding: 0; }
    [data-mobile='false'] #ActionsHeader .dropdown-menu .dropdown-item span {
      display: none; }

[data-mobile='true'] [data-hide-on='mobile'],
[data-mobile='true'] [data-hide-on='grid,mobile'] {
  display: none; }

[data-mobile='true'] .item-list {
  overflow-x: hidden !important;
  overflow-y: auto !important; }

[data-mobile='true'] table {
  display: flex;
  flex-direction: column;
  margin-top: -1px; }
  [data-mobile='true'] table tbody {
    display: flex;
    flex-direction: column; }
    [data-mobile='true'] table tbody tr {
      display: flex;
      align-items: center;
      border-top: 1px solid #ddd; }
      [data-mobile='true'] table tbody tr td {
        border: none; }
        [data-mobile='true'] table tbody tr td:nth-child(2) {
          max-width: 100%; }
  [data-mobile='true'] table .custom-checkbox {
    opacity: 1 !important; }

[data-mobile='true'] #ActionsHeader {
  margin: 0.2rem;
  position: fixed;
  right: 0;
  top: 0; }
  [data-mobile='true'] #ActionsHeader i {
    color: #ffffff; }
  [data-mobile='true'] #ActionsHeader .dropdown-menu {
    position: fixed !important;
    left: auto !important;
    margin: 60px 0.2rem 0 0 !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 99 !important; }
    [data-mobile='true'] #ActionsHeader .dropdown-menu .dropdown-item {
      padding: 0.25rem 1.5rem !important; }
      [data-mobile='true'] #ActionsHeader .dropdown-menu .dropdown-item i {
        display: none; }

[data-mobile='true'] .tooltip {
  display: none; }

@media (min-width: 768px) {
  .directories .container-wrapper {
    height: 100vh; } }

@media (max-width: 768px) {
  [data-hide-on='mobile'],
  [data-hide-on='grid,mobile'] {
    display: none; } }

.terms .container ol,
.terms .container ul {
  padding-left: 1rem;
  color: #77838f; }
  .terms .container ol li,
  .terms .container ul li {
    margin-bottom: 1rem; }
  .terms .container ol h6,
  .terms .container ul h6 {
    font-weight: bold; }

.terms .container > div:last-child hr {
  display: none; }

@media (min-width: 992px) {
  .terms .container {
    max-width: 750px; } }

@media screen and (min-width: 67.5em) {
  .terms .container {
    max-width: 990px; } }

.not-found .jumbotron {
  background-size: 100%;
  background-color: #f0f1f3;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 100vh; }

.scale-half {
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

/*------------------------------------
  Gradients
------------------------------------*/
.gradient-half-secondary-body {
  background-image: -webkit-linear-gradient(300deg, #365c6c 0%, rgba(96, 186, 235, 0.8) 100%);
  background-image: linear-gradient(150deg, #365c6c 0%, rgba(96, 186, 235, 0.8) 100%);
  background-repeat: repeat-x;
  background-attachment: fixed; }

.gradient-half-secondary {
  background-image: -webkit-linear-gradient(300deg, #365c6c 0%, rgba(96, 186, 235, 0.8) 100%);
  background-image: linear-gradient(150deg, #365c6c 0%, rgba(96, 186, 235, 0.8) 100%);
  background-repeat: repeat-x; }

/*------------------------------------
  Link-muted
------------------------------------*/
.link-muted {
  color: #365c6c;
  border-bottom: 1px dashed #365c6c;
  text-decoration: none !important; }

.link-muted:hover {
  color: rgba(54, 92, 108, 0.6);
  border-color: rgba(54, 92, 108, 0.6); }

/*------------------------------------
  Height
------------------------------------*/
.height-40vh {
  height: 40vh; }

.height-60vh {
  height: 60vh; }

.min-height-100vh {
  min-height: 100vh; }

.min-height-20 {
  min-height: 1.25rem; }

.min-height-155 {
  min-height: 9.6875rem; }

.min-height-300 {
  min-height: 18.75rem; }

.min-height-380 {
  min-height: 23.75rem; }

.min-height-450 {
  min-height: 28.125rem; }

.min-height-550 {
  min-height: 34.375rem; }

.min-height-600 {
  min-height: 37.5rem; }

.min-height-620 {
  min-height: 38.75rem; }

@media (min-width: 768px) {
  .min-height-100vh--md {
    min-height: 100vh; } }

@media (min-width: 992px) {
  .min-height-auto--lg {
    min-height: auto; }
  .min-height-100vh--lg {
    min-height: 100vh; } }

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-0_2 {
  opacity: 0.2; }

/*------------------------------------
  Overflow
------------------------------------*/
.overflow-hidden {
  overflow: hidden; }

/*------------------------------------
  Position Spaces
------------------------------------*/
.position-absolute-top-0 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

.position-absolute-top-right-0 {
  position: absolute;
  top: 0;
  right: 0; }

.position-absolute-top-left-0 {
  position: absolute;
  top: 0;
  left: 0; }

.position-absolute-top-bottom-0 {
  position: absolute;
  top: 0;
  bottom: 0; }

.position-absolute-bottom-0 {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0; }

.position-absolute-bottom-right-0 {
  position: absolute;
  right: 0;
  bottom: 0; }

.position-absolute-bottom-left-0 {
  position: absolute;
  bottom: 0;
  left: 0; }

.bottom-0 {
  bottom: 0; }

.bottom-50 {
  bottom: 3.125rem; }

.right-0 {
  right: 0; }

.left-0 {
  left: 0; }

.left-15x {
  left: 15%; }

.left-50x {
  left: 50%; }

@media (min-width: 768px) {
  .position-absolute-top-left-0--md {
    position: absolute;
    top: 0;
    left: 0; }
  .position-absolute-top-right-0--md {
    position: absolute;
    top: 0;
    right: 0; }
  .position-absolute-bottom-right-0--md {
    position: absolute;
    right: 0;
    bottom: 0; } }

@media (min-width: 992px) {
  .position-absolute-top-left-0--lg {
    position: absolute;
    top: 0;
    left: 0; }
  .position-absolute-bottom-0--lg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0; }
  .position-absolute-top-right-0--lg {
    position: absolute;
    top: 0;
    right: 0; } }

/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.transition-3d-hover:hover,
.transition-3d-hover:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px); }

/*------------------------------------
  Typography Font Size
------------------------------------*/
.font-size-8 {
  font-size: 8px !important; }

.font-size-10 {
  font-size: 10px !important; }

.font-size-13 {
  font-size: 0.8125rem !important; }

.font-size-14 {
  font-size: 0.875rem !important; }

.font-size-15 {
  font-size: 0.9375rem !important; }

.font-size-26 {
  font-size: 1.625rem !important; }

.font-size-32 {
  font-size: 2rem !important; }

@media (max-width: 991px) {
  .font-size-48--md-down {
    font-size: 3rem; } }

/*------------------------------------
  Line Height
------------------------------------*/
.text-lh-md {
  line-height: 1.8; }

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-minus-1 {
  z-index: -1; }

.z-index-2 {
  z-index: 2; }

.z-index-10 {
  z-index: 10 !important; }

/*------------------------------------
  Space
------------------------------------*/
.space-1 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.space-1-top {
  padding-top: 2rem !important; }

.space-1-bottom {
  padding-bottom: 2rem !important; }

.space-2 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important; }

.space-2-top {
  padding-top: 4.0625rem !important; }

.space-2-bottom {
  padding-bottom: 4.0625rem !important; }

.space-3 {
  padding-top: 8.125rem !important;
  padding-bottom: 8.125rem !important; }

.space-3-top {
  padding-top: 8.125rem !important; }

.space-3-bottom {
  padding-bottom: 8.125rem !important; }

.space-4 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important; }

.space-4-top {
  padding-top: 12.5rem !important; }

.space-4-bottom {
  padding-bottom: 12.5rem !important; }

.space-5 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important; }

.space-5-top {
  padding-top: 15rem !important; }

.space-5-bottom {
  padding-bottom: 15rem !important; }

.space-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.space-0-top {
  padding-top: 0 !important; }

.space-0-bottom {
  padding-bottom: 0 !important; }

@media (min-width: 576px) {
  .space-3--sm {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important; }
  .space-5-top--sm {
    padding-top: 15rem !important; } }

@media (min-width: 768px) {
  .space-1-top--md {
    padding-top: 2rem; }
  .space-2-top--md {
    padding-top: 4.0625rem; }
  .space-5-top--md {
    padding-top: 15rem; } }

@media (min-width: 992px) {
  .space-3--lg {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem; }
  .space-3-top--lg {
    padding-top: 8.125rem; }
  .space-4--lg {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem; }
  .space-4-top--lg {
    padding-top: 12.5rem; }
  .space-5-top--lg {
    padding-top: 15rem; }
  .space-5-bottom--lg {
    padding-bottom: 15rem; }
  .space-0--lg {
    padding-top: 0;
    padding-bottom: 0; }
  .space-bottom-0--lg {
    padding-bottom: 0; } }

@media (min-width: 1200px) {
  .space-4-top--xl {
    padding-top: 12.5rem; }
  .space-5-top--xl {
    padding-top: 15rem; } }

/*------------------------------------
  Divider
------------------------------------*/
.divider {
  position: relative;
  display: inline-block; }

.divider::before,
.divider::after {
  position: absolute;
  top: 50%;
  content: ''; }

.divider::before {
  right: 100%;
  background-image: -webkit-linear-gradient(45deg, transparent 0%, #bfc3ca 100%);
  background-image: linear-gradient(45deg, transparent 0%, #bfc3ca 100%);
  background-repeat: repeat-x; }

.divider::after {
  left: 100%;
  background-image: -webkit-linear-gradient(45deg, #bfc3ca 0%, transparent 100%);
  background-image: linear-gradient(45deg, #bfc3ca 0%, transparent 100%);
  background-repeat: repeat-x; }

.divider--xs {
  font-size: 0.75rem; }

.divider--xs::before,
.divider--xs::after {
  width: 2rem;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.divider--xs::before {
  margin-right: 0.625rem; }

.divider--xs::after {
  margin-left: 0.625rem; }

.divider--xs::before,
.divider--xs::after {
  width: 2rem; }

/*------------------------------------
  Square
------------------------------------*/
.square {
  position: relative;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  .square:before {
    position: absolute; }
  .square:after {
    content: '';
    display: block;
    padding-top: 100%; }

/*------------------------------------
  Transition
------------------------------------*/
.transition {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

