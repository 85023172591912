.tree {
    &.expanded {
        > .menu-button {
            i {
                transform: rotate(90deg);
                transition: transform 0.2s ease;
            }
        }
    }
}
