// -----------------------------------------------------------------------------
// This file contains all styles related to the search component.
// -----------------------------------------------------------------------------

.search-box {
    max-width: 720px;
}

[data-mobile='true'] {
    .search-box {
        z-index: 0 !important;
    }
}
