// -----------------------------------------------------------------------------
// This file contains all styles related to the preview of the site/application.
// -----------------------------------------------------------------------------

.preview-wrapper {
    align-items: flex-end;
    top: 0;
    display: flex;
    height: 100vh;
    left: 0;
    position: fixed;
    transition: transform 0.3s ease;
    will-change: transform;
    width: 100vw;
    z-index: 9999;

    div {
        position: relative;
    }

    > div {
        background-color: $gray-100;
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;

        > .content {
            bottom: 0;
            box-sizing: inherit;
            left: 0;
            overflow: hidden;
            padding-top: 0;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            transition: all 0.3s;
            will-change: width, padding-top, transform;

            &.drawer-opened {
                .drawer {
                    animation-name: drawerIn;
                    animation-duration: 0.3s;
                    animation-fill-mode: forwards;
                    .drawer-menu {
                        transform: translateX(0);
                    }
                    .drawer-bg {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .action-bar,
    .pager li {
        height: 3rem;
        max-height: 3rem;
        min-height: 3rem;
    }

    .action-bar {
        display: flex;
        font-weight: bold;
        height: 3rem;
        line-height: 3rem;
        max-height: 3rem;
        min-height: 3rem;
        padding: 0 1rem;
        .icon {
            cursor: pointer;
            font-size: 1.5rem;
            margin-right: 1.5rem;
        }
    }

    .dialog {
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0 1rem;
        position: absolute;
        width: 100%;
        z-index: 2;

        > .card {
            border: none;
            box-shadow: 0 0.5rem 1rem rgba(#000000, 0.15);
            max-height: 100%;
            width: 100%;

            .card-title {
                font-weight: bold;
            }

            .card-footer {
                background-color: transparent;
                border: none;
                text-align: right;

                button {
                    color: #000000;
                    font-size: 0.9rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .drawer {
        animation-name: drawerOut;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        z-index: 9;
        .drawer-menu {
            background: #ffffff;
            height: 100%;
            width: 75%;
            transform: translateX(-100%);
            transition: transform 0.3s ease;
            z-index: 1;

            .subtitle,
            .title {
                font-size: 0.8rem;
                font-weight: 500;
            }
            .subtitle {
                display: block;
                opacity: 0.7;
            }
            .title {
                display: block;
            }

            header {
                height: auto;
                padding: 1rem;
                &:empty {
                    display: none;
                }

                .icon {
                    width: 50px;
                    display: block;
                    margin-bottom: 0.5rem;
                }
            }
            .drawer-content {
                > .items {
                    > .item {
                        border-bottom: 1px solid #dddddd;
                        padding: 1rem !important;
                        &:last-child {
                            border-bottom: none;
                        }
                        > .icon {
                            margin: 0 1.2rem 0 0;
                        }
                        > .title {
                            opacity: 0.7;
                        }

                        .items {
                            width: 100%;
                        }
                    }
                }
                .item {
                    align-items: center;
                    display: flex;
                    font-size: 0.8rem;
                    flex-wrap: wrap;
                    padding: 1rem 0 0 1.2rem;
                    .icon {
                        width: 1.2rem;
                        margin: 0 1.2rem 0 -1.2rem;
                    }
                }
            }
        }
        .drawer-bg {
            background-color: rgba(0, 0, 0, 0.6);
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity 0.3s ease;
            z-index: 0;
        }
    }

    .pager {
        overflow-x: auto;
        overflow-y: hidden;
        &[data-tabs-position='bottom'] {
            order: 2;
        }
        &[data-tab-style='icon'] {
            li {
                height: 3rem;
                max-height: 3rem;
                min-height: 3rem;
                span {
                    display: none;
                }
            }
        }
        &[data-tab-style='icon_title'] {
            ul.pager-tabs {
                li {
                    height: 3.75rem;
                    line-height: 1.2rem;
                    max-height: 3.75rem;
                    min-height: 3.75rem;
                    width: 100%;
                    span {
                        font-size: 0.7rem;
                        font-weight: bold;
                    }
                }
            }
        }
        &[data-tab-style='title'] {
            .icon {
                display: none;
            }
        }

        .pager-index {
            bottom: 0;
            height: 3px;
            position: absolute;
            transition: all 0.3s ease;
            z-index: 0;
        }
        ul.pager-tabs {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            > li {
                flex-grow: 1;
                line-height: 3rem;
                text-align: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 1;

                .icon {
                    background-color: currentColor;
                    height: 26px;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: 26px;
                    width: 100%;
                }
            }
        }
    }

    .main-content {
        display: flex;
        flex-grow: 1;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;

        > .component-wrapper {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-width: 100%;
            min-width: 100%;
            scroll-snap-align: center;
            width: 100%;

            > .visor {
                width: 100%;
                height: 100%;
                overflow-y: auto;
            }
        }
    }

    .hover {
        box-shadow: 0 0 0 1px $primary, 0 0 4px 2px $primary;
        opacity: 0.9;
        z-index: 2;
        &:hover {
            box-shadow: none;
            opacity: 1 !important;
        }
        & ~ div {
            z-index: 2;
        }
    }

    .no-google-services {
        display: block;
        padding: 0.25rem;
        z-index: 3;
    }
}

.visor {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    > div {
        display: flex;
    }

    .component-wrapper {
        &[data-width='100%'] {
            width: -webkit-fill-available !important;
        }
        &[data-height='fill'],
        &[data-height*='%'] {
            height: 100% !important;
            max-height: 100% !important;
            min-height: 100% !important;
        }
        &[data-height='wrap'],
        &[data-height='wrap'] > .visor {
            height: fit-content;
        }
    }

    iframe {
        border: none;
        display: block;
    }

    &[data-direction='column'] {
        flex-wrap: wrap;
        > div {
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;

            &[data-position='absolute'] {
                > div {
                    position: relative !important;
                }
            }
        }
    }

    &[data-direction='row'] {
        > div {
            > .component-wrapper {
                max-width: 100% !important;
                min-width: 100% !important;
                width: 100% !important;
            }
        }
    }

    > div {
        &[data-position='absolute'] {
            position: absolute;
            top: 0;
            left: 0;
            &[data-halign='center'] {
                left: 50%;
                transform: translate(-50%, 0);
                &[data-valign='center'] {
                    transform: translate(-50%, -50%);
                }
            }
            &[data-halign='end'] {
                left: unset;
                right: 0;
            }
            &[data-valign='center'] {
                top: 50%;
                transform: translate(0, -50%);
            }
            &[data-valign='bottom'] {
                top: unset;
                bottom: 0;
            }
        }

        &[data-halign='center'] {
            justify-content: center;
        }
        &[data-halign='end'] {
            justify-content: flex-end;
        }
        &[data-valign='center'] {
            align-items: center;
        }
        &[data-valign='bottom'] {
            align-items: flex-end;
        }

        > .component-wrapper {
            overflow: hidden;
            background-position: center;
            background-size: cover;
            &[data-type='banner'] {
                align-items: center !important;
                display: flex;
                justify-content: center !important;
                width: 100%;
                &:before {
                    content: '';
                    padding-top: 15%;
                    display: block;
                }
                > img {
                    position: absolute;
                    width: 100%;
                }
            }
            &[data-type='button'] {
                > div {
                    align-items: center;
                    border-radius: 4rem;
                    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
                    display: flex;
                    height: 4rem;
                    justify-content: center;
                    padding: 0.75rem;
                    width: 4rem;
                    > div {
                        background-position: center;
                        background-size: contain;
                        height: 75%;
                        width: 75%;
                    }
                }
            }

            &[data-type='canvas'] {
                canvas {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }

            &[data-type='cropper'] {
                align-items: center;
                display: flex;
                justify-content: center;
                .cropper {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    .img {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 100%;
                        &:before {
                            content: '';
                            display: block;
                            padding-top: 66.66%;
                        }
                    }
                    &[data-mode='16:9'] {
                        .frame-wrapper {
                            width: 80%;
                            &:before {
                                padding-top: 56.25%;
                            }
                        }
                    }
                    &[data-mode='9:16'] {
                        .frame-wrapper {
                            width: 30%;
                            &:before {
                                padding-top: 177.78%;
                            }
                        }
                    }
                    &[data-mode='4:3'] {
                        .frame-wrapper {
                            width: 70%;
                            &:before {
                                padding-top: 75%;
                            }
                        }
                    }
                    &[data-mode='3:4'] {
                        .frame-wrapper {
                            &:before {
                                padding-top: 133.33%;
                            }
                        }
                    }
                    &[data-mode='free'] {
                        .frame-wrapper {
                            &:before {
                                padding-top: 50%;
                            }
                        }
                    }
                    &[data-mode='fit'] {
                        .frame-wrapper {
                            height: 100%;
                            width: 100%;
                            &:before {
                                display: none;
                            }
                        }
                    }
                    &[data-mode='square'] {
                        .frame-wrapper {
                            &:before {
                                padding-top: 100%;
                            }
                        }
                    }
                    &[data-mode='circle'],
                    &[data-mode='circle_square'] {
                        .frame-wrapper {
                            border-radius: 10rem;
                            &:before {
                                padding-top: 100%;
                            }
                        }
                    }
                    .frame-wrapper {
                        align-items: center;
                        color: #00000096;
                        box-shadow: 0 0 0 100rem;
                        display: flex;
                        justify-content: center;
                        position: absolute;
                        width: 40%;
                        &:before {
                            content: '';
                            display: block;
                            padding-top: 100%;
                        }
                        .frame {
                            color: $primaryDark;
                            border: 1px solid;
                            height: 100%;
                            position: absolute;
                            width: 100%;
                            .guides,
                            .handlers {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                            }
                            .guides {
                                color: $primary;
                                position: absolute;
                                > div {
                                    position: absolute;
                                    &:nth-child(1),
                                    &:nth-child(2) {
                                        height: 100%;
                                        border-left: 1px solid;
                                    }
                                    &:nth-child(3),
                                    &:nth-child(4) {
                                        width: 100%;
                                        border-top: 1px solid;
                                    }
                                    &:nth-child(1) {
                                        left: 33.33%;
                                    }
                                    &:nth-child(2) {
                                        left: 66.66%;
                                    }
                                    &:nth-child(3) {
                                        top: 33.33%;
                                    }
                                    &:nth-child(4) {
                                        top: 66.66%;
                                    }
                                }
                            }
                        }
                        .handlers {
                            color: $primaryDark;
                            height: 100%;
                            position: absolute;
                            width: 100%;
                            > div {
                                background-color: currentColor;
                                border-radius: 2rem;
                                height: 2rem;
                                position: absolute;
                                width: 2rem;
                                &:nth-child(1) {
                                    left: -1.1rem;
                                    top: -1.1rem;
                                }
                                &:nth-child(2) {
                                    right: -1.1rem;
                                    top: -1.1rem;
                                }
                                &:nth-child(3) {
                                    bottom: -1.1rem;
                                    right: -1.1rem;
                                }
                                &:nth-child(4) {
                                    bottom: -1.1rem;
                                    left: -1.1rem;
                                }
                            }
                        }
                    }
                }
            }

            &[data-type='group'] {
                display: flex;
                &[data-direction='column'] {
                    flex-direction: column;
                }
                > .visor {
                    flex-grow: 1;
                    height: 100%;
                    width: 100%;
                    &[data-scroll='true'] {
                        &[data-direction='column'] {
                            overflow-y: auto;
                        }
                        &[data-direction='row'] {
                            overflow-x: auto;
                        }
                    }
                }
            }

            &[data-type='list'] {
                display: flex;
                &[data-direction='column'] {
                    flex-direction: column;
                }
            }
            &[data-type='image'] {
                > div {
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100%;
                    width: 100%;
                    &[data-scale] {
                        &[data-scale='crop'] {
                            background-size: cover;
                            img {
                                display: none;
                            }
                        }
                    }
                }

                &[data-height='100%'][data-width='100%'] {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        height: 100%;
                        object-fit: fill;
                        position: absolute;
                        width: 100%;
                    }
                }

                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }

            &[data-type='text'] {
                > div {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    word-break: break-word;
                }

                [data-align='flex-start'] {
                    justify-content: flex-start !important;
                    text-align: left;
                }
                [data-align='center'] {
                    justify-content: center !important;
                    text-align: center;
                }
                [data-align='flex-end'] {
                    justify-content: flex-end !important;
                    text-align: right;
                }

                [data-valign='top'] {
                    align-items: flex-start !important;
                }
                [data-valign='middle'] {
                    align-items: center !important;
                }
                [data-valign='bottom'] {
                    align-items: flex-end !important;
                }
            }

            &[data-type='inputtext'] {
                padding: 1px;
                > input {
                    background-color: transparent;
                    border: none;
                    border-bottom: 2px solid $secondaryLight;
                    outline: none;
                    width: 100%;
                    &:focus {
                        border-color: $primary;
                    }
                }
            }

            &[data-type='map'],
            &[data-type='pdfviewer'],
            &[data-type='web'] {
                iframe {
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }
            }

            &[data-type='player'] {
                display: flex;
                flex-direction: column;
                > div {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    height: 100%;
                    justify-content: center;
                    width: 100%;
                }
                audio {
                    outline: none;
                    width: 100%;
                }
            }

            &[data-type='map'] {
                height: 300px;
                width: 100%;
                .no-apikey {
                    display: block;
                    padding: 0.25rem;
                }
            }

            &[data-type='range'] {
                align-items: center;
                display: flex;
                .noUi-target {
                    background: rgba(0, 0, 0, 0.15);
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    margin: 0.5rem 1rem;
                    &.noUi-horizontal {
                        height: 4px;
                        width: 100%;
                        .noUi-origin {
                            align-items: center;
                            height: 4px;
                        }
                    }
                    &.noUi-vertical {
                        height: 100%;
                        width: 4px;
                        .noUi-origin {
                            justify-items: center;
                            width: 4px;
                        }
                    }
                    .noUi-base {
                        .noUi-connects {
                            border-radius: 0;
                        }
                        .noUi-origin {
                            display: flex;
                        }
                    }
                }

                .noUi-handle {
                    background-color: #fff;
                    border: none;
                    border-radius: 100rem;
                    box-shadow: none;
                    cursor: pointer;
                    height: 0.8rem;
                    min-height: 0rem;
                    min-width: 0rem;
                    outline: none;
                    position: absolute;
                    right: -0.3rem;
                    top: unset;
                    transition: all 0.2s ease;
                    width: 0.8rem;
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }

            &[data-type='scanner'] {
                div {
                    align-items: center;
                    background-color: $primaryDark;
                    box-shadow: inset 0 0 6rem -2rem rgba(0, 0, 0, 0.8);
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    perspective: 500px;
                    position: absolute;
                    width: 100%;
                    canvas {
                        background-color: #ffffff;
                        padding: 5%;
                        position: absolute;
                        width: 90% !important;
                        height: auto !important;
                        max-height: 150%;
                        transform: rotate3d(1, 0, 0, 30deg);
                    }
                }
            }

            &[data-type='select'] {
                overflow: visible;
                .dropdown {
                    .dropdown-toggle {
                        align-items: center;
                        background-color: transparent;
                        border: none;
                        border-radius: 0 !important;
                        box-shadow: none !important;
                        color: inherit;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    }
                    .dropdown-menu {
                        border: none;
                        border-radius: 0;
                        box-shadow: 0 0.5rem 1rem rgba(#000000, 0.35);
                        color: inherit;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        .dropdown-item {
                            background-color: transparent !important;
                            color: inherit !important;
                        }
                    }
                }
            }

            &[data-type='switch'] {
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    input[type='checkbox'] {
                        height: 0;
                        width: 0;
                        visibility: hidden;

                        &:checked + label {
                            &:after {
                                background-color: $primary;
                                left: calc(100% - 1.4rem);
                            }
                        }

                        & + label {
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            height: 1rem;
                            margin: 0.5rem;
                            position: relative;
                            width: 2.5rem;

                            &:before {
                                background-color: $gray-500;
                                box-shadow: inset 0 0 0 1rem rgba(0, 0, 0, 0.2);
                                border-radius: 1rem;
                                content: '';
                                height: 100%;
                                transition: background-color 0.3s ease;
                                width: 100%;
                                will-change: background-color;
                            }

                            &:after {
                                background-color: #fff;
                                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15),
                                    0 0.15rem 0.15rem rgba(0, 0, 0, 0.25);
                                border-radius: 2rem;
                                content: '';
                                height: 1.4rem;
                                left: 0;
                                min-height: 1.4rem;
                                min-width: 1.4rem;
                                position: absolute;
                                transition: all 0.2s ease;
                                width: 1.4rem;
                                will-change: left, background-color;
                            }
                        }
                    }
                }
            }
            &[data-type='video'] {
                display: flex;
                flex-direction: column;
                > div,
                video {
                    height: 100%;
                    width: 100%;
                }

                &[data-height='wrap'] {
                    video {
                        height: auto;
                    }
                }

                > div {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: center;
                }
            }
            &[data-type='youtube'] {
                > div {
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 56.25%;
                    }
                    &[data-api-key=''] {
                        &:before {
                            padding-top: 80%;
                        }
                    }
                    .dialog {
                        top: 0;
                    }
                    iframe {
                        height: 100%;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}
