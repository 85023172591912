// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Raleway', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif !default;

/// Regular font size
$text-font-size: 1rem;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

$border-radius: 0.25rem !default;

/// Bootstrap colors system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
    ),
    $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #722ed1 !default;
$pink: #e83e8c !default;
$red: #ff4d4f !default;
$orange: #fa8c16 !default;
$yellow: #fadb14 !default;
$green: #52c41a !default;
$teal: #20c997 !default;
$cyan: #13c2c2 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
        'blue': $blue,
        'indigo': $indigo,
        'purple': $purple,
        'pink': $pink,
        'red': $red,
        'orange': $orange,
        'yellow': $yellow,
        'green': $green,
        'teal': $teal,
        'cyan': $cyan,
        'white': $white,
        'gray': $gray-600,
        'gray-dark': $gray-800,
    ),
    $colors
);

$primary: #60baeb !default;
$secondary: #365c6c !default;

$primaryLight: lighten($primary, 15%) !default;
$primaryDark: darken($primary, 15%) !default;
$primaryText: #212529;

$secondaryLight: lighten($secondary, 15%) !default;
$secondaryDark: darken($secondary, 15%) !default;
$secondaryText: #1b3946;

$yellow: #fce359;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$gray: $gray-400 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'white': $white,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'gray': $gray,
        'dark': $dark,
    ),
    $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 168 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

/// Bootstrap margin and padding
/// @type Float
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        -10: ($spacer * -10),
        -7: ($spacer * -5),
        -6: ($spacer * -4),
        -5: ($spacer * -3),
        -4: ($spacer * -1.5),
        -3: ($spacer * -1),
        -2: ($spacer * -0.5),
        -1: ($spacer * -0.25),
        0: 0,
        1: ($spacer * 0.25),
        2: ($spacer * 0.5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 4),
        7: ($spacer * 5)
    ),
    $spacers
);

$border-width: 1px !default;
$border-color: $gray-300 !default;

$input-btn-border-width: $border-width !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$component-active-bg: $primary !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$line-height-base: 1.5 !default;

$input-color: $gray-700 !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-border-width: $border-width !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

/// Header's height
/// @type Length
$header-height: 70px;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
    'small': 320px,
    'medium': 768px,
    'large': 1024px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
