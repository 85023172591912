// -----------------------------------------------------------------------------
// This file contains all styles related to the drop-wrapper component.
// -----------------------------------------------------------------------------

.drop-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 1rem;
    z-index: 1009;
    visibility: hidden;

    > .bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 30%;
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
        transform: scale(1, 0);
        transform-origin: top;
        transition: transform 0.3s ease;
    }

    > .alert {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 1rem 1px;
        font-size: 0.9rem;
        line-height: 34px;
        transform: translate(0, -200px);
        transition: transform 0.3s ease;
        transition-delay: 0.1s;
        z-index: 1;
    }

    &.show {
        visibility: visible;
        > .bg {
            transform: scale(1);
        }
        > .alert {
            transform: translate(0, 0px);
        }
    }
}

.overwrite-modal {
    .overwrite-preview {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 1rem;
        padding: 1rem 0;
        white-space: nowrap;

        &:first-child {
            margin: 0;
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }

        > div {
            width: 100%;
            &:first-child {
                border: 1px solid transparent;
                display: flex;
                flex-grow: 1;
                margin-bottom: 0.5rem;

                > div {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            &:last-child {
                align-items: center;
                display: flex;
                input {
                    margin: 0.1rem 0.5rem 0.2rem;
                }
                label {
                    margin: 0;
                }
            }
        }

        .old-file,
        .new-file {
            border: 1px solid $gray-300;
            flex-grow: 1;
            padding: 1rem;
            width: 0;
        }

        .old-file {
            margin-right: 1rem;
        }

        .delete-x {
            align-self: center;
            color: $danger;
            display: none;
            font-size: 5rem;
            padding-bottom: calc(22px + 0.5rem);
            position: absolute;
            text-shadow: 0 0 2rem #fff;
            z-index: 1;
        }

        .preview {
            overflow: visible;

            .preview-body {
                height: 200px;
                .img {
                    height: 100%;
                    transform: none !important;
                }
                audio,
                video {
                    width: 100%;
                }
            }

            .preview-footer {
                flex-direction: column;
                span {
                    margin: 0 0 0.5rem;
                }
            }
        }

        span {
            display: block;
            margin-top: 0.5rem;
            max-width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;

            &.keep-both-name,
            &.overwrite-name {
                display: none;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &[data-overwrite='true'] {
            > div:first-child {
                border-color: $gray-300;
                > div {
                    border-color: transparent;
                }
            }
            .old-file {
                > span {
                    visibility: hidden;
                }
                .preview {
                    opacity: 0.4;
                }
            }
            .delete-x,
            .overwrite-name {
                display: block;
                margin: 0.5rem 0 0 calc(-100% - 3rem);
                max-width: 430px;
            }
        }
        &[data-overwrite='false'] {
            .keep-both-name {
                display: block;
            }
        }
    }
}
