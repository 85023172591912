.placeholder {
    width: 100%;
    background: $gray-400;
    opacity: 0.65;

    &.placeholder-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        border-radius: 3px;
        color: rgba($primary, 0.3);
        &:before {
            position: absolute;
        }
        &:after {
            content: '';
            display: block;
            padding-top: 60%;
        }
    }

    &.placeholder-text {
        display: block;
        width: 80%;
        height: 1.2rem;
        border-radius: 50rem;
        &.sm-placeholder {
            height: 0.9rem;
        }
        &.lg-placeholder {
            height: 2rem;
        }
    }
}

.placeholder-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-200;

    .mdi {
        font-size: 8rem;
    }
}
