// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
    padding-bottom: 80px;

    .container {
        max-width: 930px;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 576px) {
    footer {
        .container {
            max-width: 510px;
        }
    }
}

@media (min-width: 768px) {
    footer {
        padding-bottom: 0;
        .container {
            max-width: 690px;
        }
    }
}

@media (min-width: 992px) {
    footer {
        .container {
            max-width: 930px;
        }
    }
}

@media (min-width: 1200px) {
    footer {
        .container {
            max-width: 1110px;
        }
    }
}
