/**
* Button
**/

.ant-btn {
    border-color: $primary;
    border-radius: 0.25rem;
    color: $primary;
    text-shadow: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus,
    &:hover {
        background: $primary;
        border-color: $primary;
        color: #ffffff;
    }
    &.ant-btn-primary {
        background: $primary;
        border-color: $primary;
        color: #ffffff;
        &:hover {
            background: $primaryDark;
            border-color: $primaryDark;
        }
    }
}

/**
* Checkbox
**/

.ant-checkbox-wrapper {
    &:hover {
        .ant-checkbox-inner {
            border-color: $primary;
        }
    }
}

.ant-checkbox {
    &:hover {
        .ant-checkbox-inner {
            border-color: $primary;
        }
    }
}

.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
        &:after {
            background-color: $primary;
        }
    }
}

.ant-checkbox-input {
    &:focus {
        + .ant-checkbox-inner {
            border-color: $primary;
        }
    }
}

.ant-checkbox-checked {
    &:after {
        border: 1px solid $primary;
    }
    .ant-checkbox-inner {
        background-color: $primary;
        border-color: $primary;
    }
}

/**
* Input
**/

.ant-slider + .ant-input,
.ant-slider + .ant-input-number {
    margin-left: 0.5rem;
}
.ant-input {
    width: auto;

    &:focus,
    &:hover,
    &.ant-input-focused {
        border-color: $primary;
        box-shadow: none;
    }
}
.ant-input-number {
    width: 65px;

    &:focus,
    &:hover,
    &.ant-input-number-focused {
        border-color: $primary;
        box-shadow: none;
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: $primary;
    }
}

/**
* Menu
**/

.ant-menu {
    background: transparent;

    .ant-menu-item {
        &:hover,
        &.ant-menu-item-selected {
            color: $primary;
        }
        &.ant-menu-item-selected {
            background-color: lighten($primary, 35%);
        }
        &:after {
            border-color: $primary;
        }
    }

    .ant-menu-submenu-arrow {
        &:before,
        &:after {
            background: $gray-500;
        }
    }

    .ant-menu-submenu-selected {
        border-color: $primary;
        color: $primary;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        &:hover,
        &.ant-menu-item-selected {
            border-color: $primary;
            color: $primary;
            .ant-menu-submenu-arrow {
                &:before,
                &:after {
                    background: $primary;
                }
            }
        }
    }
}

/**
* Message
**/

.ant-message {
    z-index: 99999;

    .ant-message-notice {
        padding: 0 1rem 0 0 !important;
        text-align: right;
        visibility: hidden;
        .ant-message-notice-content {
            border-radius: 0.3rem;
            overflow: hidden;
            position: relative;
            visibility: visible;
            .ant-message-custom-content {
                display: flex;
                align-items: center;
            }
        }
    }

    .anticon {
        margin-right: 1rem;
    }

    ul {
        margin: 0;
        padding: 0;
        text-align: left;

        li {
            list-style: none;
        }
    }
}

/**
* Notifications
**/

.ant-notification {
    .ant-notification-notice-description {
        .modal-footer {
            border: none;
            padding: 1.25rem 0 0;
            .btn-link {
                text-transform: uppercase;
            }
        }
    }
}

/**
* Popover
**/

.ant-popover-inner-content {
    max-height: 100vh;
    overflow: auto;

    [contenteditable] {
        display: block;
        padding: 0.25rem 0.5rem;
        font-weight: 400;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        margin-left: 0.5rem;
        min-width: 50px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 4px rgba($primary, 0.12);
            outline: none;
        }
    }
}

/**
* Progress
**/

.ant-progress {
    .ant-progress-bg {
        background: $primary;
    }
    .ant-progress-circle-path {
        stroke: linear-gradient(150deg, $secondary 0%, rgba($primary, 0.8) 100%);
    }
}

.ant-message {
    .ant-progress {
        align-items: center;
        display: flex;
        position: absolute;
        height: 22px;
        width: calc(100% - 2rem);

        &.ant-progress-status-success {
            .ant-progress-bg {
                background: $success;
            }
        }

        .ant-progress-inner {
            border-radius: 0;
            height: 3px;
            left: -16px;
            position: absolute;
            top: -10px;
            width: calc(100% + 32px);
            .ant-progress-bg,
            .ant-progress-bg:before {
                border-radius: 0;
            }
        }
        .ant-progress-text {
            display: flex;
            justify-content: center;
            .anticon {
                margin: 0;
            }
        }
    }
}

/**
* Select
**/

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $gray-400;
    border-radius: 0.25rem;
}
.ant-select {
    &:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border-color: $gray-400;
            }
        }
        &.ant-select-focused {
            .ant-select-selector {
                border-color: $primary !important;
                box-shadow: 0 0 0 4px rgba($primary, 0.12) !important;
            }
        }
    }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: lighten($primary, 35%);
}

/**
* Slider
**/

.ant-slider {
    flex-grow: 1;

    .ant-slider-rail {
        background-color: $gray-300;
    }
    &:hover {
        .ant-slider-rail {
            background-color: $gray-400;
        }
    }
    .ant-slider-track {
        background-color: $primary !important;
    }
    .ant-slider-handle {
        border: 2px solid $primary !important;

        &:focus {
            box-shadow: 0 0 0 5px rgba($primary, 0.12) !important;
        }
    }
}

/**
* Tabs
**/

.ant-tabs {
    flex-direction: column;
    flex-grow: 1;
    display: flex;

    &.ant-tabs-card {
        .ant-tabs-nav {
            margin: 0;
            transition: none;
            &:before {
                display: none;
            }
            .ant-tabs-tab {
                cursor: default;
                background: transparent;
                border: none;
                margin: 0 !important;
                padding: 0;
                position: unset;

                .ant-tabs-tab-btn {
                    & > div {
                        align-items: center;
                        border: none;
                        background-color: transparent;
                        border-radius: 0.5rem 0.5rem 0 0;
                        color: $primaryText !important;
                        display: flex;
                        font-weight: normal;
                        height: 36px;
                        margin: 4px 0 0 !important;
                        padding: 0.5rem 1rem;
                        position: relative;
                        transition: all 0.3s ease !important;
                        &:hover {
                            box-shadow: -8px 0 0 -7px transparent;
                            color: $primaryText;
                            z-index: 1;
                            &:before,
                            &:after {
                                color: $primaryText;
                            }
                        }

                        [type='close'] {
                            align-items: center;
                            border-radius: 2rem;
                            color: $primaryText;
                            cursor: pointer;
                            display: flex;
                            font-size: 1.2rem;
                            height: 32px;
                            justify-content: center;
                            margin: 0 -0.75rem 0 0.5rem;
                            transition: background 0.3s ease;
                            width: 32px;
                        }

                        &.active {
                            background: $primaryText;
                            box-shadow: -8px 0 0 -7px transparent;
                            color: $primaryText;
                            transition: none !important;
                            z-index: 2;
                            &:before,
                            &:after {
                                color: $primaryText;
                                transition: none !important;
                            }
                        }

                        &:before,
                        &:after {
                            background: transparent;
                            bottom: 0;
                            color: transparent;
                            content: '';
                            width: 1rem;
                            height: 1rem;
                            position: absolute;
                            border-radius: 0.75rem;
                            transition: all 0.3s ease !important;
                        }
                        &:before {
                            box-shadow: 0.5rem 0.5rem 0 0 currentColor;
                            right: 100%;
                        }
                        &:after {
                            box-shadow: -0.5rem 0.5rem 0 0 currentColor;
                            left: 100%;
                        }
                    }
                }
            }
        }
        .ant-tabs-content-holder {
            flex-direction: column;
            flex-grow: 1;
            display: flex;
            .ant-tabs-content {
                flex-grow: 1;
            }
        }
    }

    &:not(.ant-tabs-card) {
        .ant-tabs-nav {
            margin: 0;
            &:before {
                display: none;
            }
            .ant-tabs-nav-list {
                display: flex;
                flex-grow: 1;

                .ant-tabs-ink-bar {
                    background: $primary;
                }

                .ant-tabs-tab {
                    flex-grow: 1;
                    font-size: 0.7rem;
                    font-weight: normal;
                    justify-content: center;
                    line-height: 40px;
                    margin: 0;
                    padding: 0 0.5rem;
                    text-align: center;
                    text-transform: uppercase;

                    &:hover,
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: $primary;
                        }
                    }
                }
            }
            .ant-tabs-nav-more {
                padding: 0 0.5rem;
            }
        }
    }
}
