// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.home {
    padding-top: $header-height;

    .jumbotron {
        background-size: cover;
        background-position: center;
        overflow: hidden;

        .banner-fluid {
            margin: -65px 0;
        }
    }

    .marketing {
        font-size: 16px;
        color: #5a5a5a;

        h2 {
            font-weight: 400;
        }

        .row {
            .col-lg-4 {
                margin-bottom: 1.5rem;
                text-align: center;

                p {
                    margin-right: 0.75rem;
                    margin-left: 0.75rem;
                }
            }

            &.featurette {
                .featurette-divider {
                    margin: 5rem 0;
                }

                .featurette-heading {
                    font-weight: bold;
                    line-height: 1.3;
                    letter-spacing: -0.05rem;
                    color: $secondary;
                }

                .lead {
                    font-size: 1rem;
                    color: $gray-800;
                }

                img {
                    width: 500px;
                    height: auto;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .featurette-heading {
        font-size: 2.5rem;
    }
}

@media (min-width: 1200px) {
    .featurette-heading {
        margin-top: 7rem;
    }
}
