// -----------------------------------------------------------------------------
// This file contains all styles related to the alerts of the site/application.
// -----------------------------------------------------------------------------

.alert-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    visibility: hidden;
    z-index: 1049;

    > .alert {
        align-items: center;
        visibility: visible;
        display: flex;
        padding-bottom: 1px;
        padding-left: 1rem;
        padding-top: 2px;
        font-size: 0.9rem;
        line-height: 34px;
        &.alert-dismissible {
            padding-right: 3rem;
        }

        > .close {
            padding: 0.4rem 1rem;
        }

        .display-progress {
            align-items: center;
            display: flex;
            margin-left: 1rem;
            max-width: 40px;
            min-width: 40px;
            text-align: right;
            width: 40px;
        }

        > .progress {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            background-color: transparent;

            > .progress-bar {
                overflow: hidden;
                display: flex;

                > span {
                    align-items: center;
                    text-align: left;
                    margin: 0 0 0 1rem;
                    font-size: 0.9rem;
                    display: flex;

                    > span:first-child {
                        flex-grow: 1;
                    }

                    > .btn {
                        margin-right: 1.25rem !important;
                    }
                }
            }
        }
    }
}

[data-mobile='true'] {
    .alert-wrapper {
        top: 56px;
    }
}
