// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.scale-half {
    transform: scale(0.5);
}

/*------------------------------------
  Gradients
------------------------------------*/

.gradient-half-secondary-body {
    background-image: linear-gradient(150deg, $secondary 0%, rgba($primary, 0.8) 100%);
    background-repeat: repeat-x;
    background-attachment: fixed;
}

.gradient-half-secondary {
    background-image: linear-gradient(150deg, $secondary 0%, rgba($primary, 0.8) 100%);
    background-repeat: repeat-x;
}

/*------------------------------------
  Link-muted
------------------------------------*/

.link-muted {
    color: $secondary;
    border-bottom: 1px dashed $secondary;
    text-decoration: none !important;
}

.link-muted:hover {
    color: rgba($secondary, 0.6);
    border-color: rgba($secondary, 0.6);
}

/*------------------------------------
  Height
------------------------------------*/
.height-40vh {
    height: 40vh;
}

.height-60vh {
    height: 60vh;
}

.min-height-100vh {
    min-height: 100vh;
}

.min-height-20 {
    min-height: 1.25rem;
}

.min-height-155 {
    min-height: 9.6875rem;
}

.min-height-300 {
    min-height: 18.75rem;
}

.min-height-380 {
    min-height: 23.75rem;
}

.min-height-450 {
    min-height: 28.125rem;
}

.min-height-550 {
    min-height: 34.375rem;
}

.min-height-600 {
    min-height: 37.5rem;
}

.min-height-620 {
    min-height: 38.75rem;
}

@media (min-width: 768px) {
    .min-height-100vh--md {
        min-height: 100vh;
    }
}

@media (min-width: 992px) {
    .min-height-auto--lg {
        min-height: auto;
    }
    .min-height-100vh--lg {
        min-height: 100vh;
    }
}

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-0_2 {
    opacity: 0.2;
}

/*------------------------------------
  Overflow
------------------------------------*/
.overflow-hidden {
    overflow: hidden;
}

/*------------------------------------
  Position Spaces
------------------------------------*/
.position-absolute-top-0 {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.position-absolute-top-right-0 {
    position: absolute;
    top: 0;
    right: 0;
}

.position-absolute-top-left-0 {
    position: absolute;
    top: 0;
    left: 0;
}

.position-absolute-top-bottom-0 {
    position: absolute;
    top: 0;
    bottom: 0;
}

.position-absolute-bottom-0 {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

.position-absolute-bottom-right-0 {
    position: absolute;
    right: 0;
    bottom: 0;
}

.position-absolute-bottom-left-0 {
    position: absolute;
    bottom: 0;
    left: 0;
}

.bottom-0 {
    bottom: 0;
}

.bottom-50 {
    bottom: 3.125rem;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.left-15x {
    left: 15%;
}

.left-50x {
    left: 50%;
}

@media (min-width: 768px) {
    .position-absolute-top-left-0--md {
        position: absolute;
        top: 0;
        left: 0;
    }
    .position-absolute-top-right-0--md {
        position: absolute;
        top: 0;
        right: 0;
    }
    .position-absolute-bottom-right-0--md {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

@media (min-width: 992px) {
    .position-absolute-top-left-0--lg {
        position: absolute;
        top: 0;
        left: 0;
    }
    .position-absolute-bottom-0--lg {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .position-absolute-top-right-0--lg {
        position: absolute;
        top: 0;
        right: 0;
    }
}

/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
    transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover,
.transition-3d-hover:focus {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}

/*------------------------------------
  Typography Font Size
------------------------------------*/
.font-size-8 {
    font-size: 8px !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-13 {
    font-size: 0.8125rem !important;
}

.font-size-14 {
    font-size: 0.875rem !important;
}

.font-size-15 {
    font-size: 0.9375rem !important;
}

.font-size-26 {
    font-size: 1.625rem !important;
}

.font-size-32 {
    font-size: 2rem !important;
}

@media (max-width: 991px) {
    .font-size-48--md-down {
        font-size: 3rem;
    }
}

/*------------------------------------
  Line Height
------------------------------------*/
.text-lh-md {
    line-height: 1.8;
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-minus-1 {
    z-index: -1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-10 {
    z-index: 10 !important;
}

/*------------------------------------
  Space
------------------------------------*/

.space-1 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.space-1-top {
    padding-top: 2rem !important;
}

.space-1-bottom {
    padding-bottom: 2rem !important;
}

.space-2 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
}

.space-2-top {
    padding-top: 4.0625rem !important;
}

.space-2-bottom {
    padding-bottom: 4.0625rem !important;
}

.space-3 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
}

.space-3-top {
    padding-top: 8.125rem !important;
}

.space-3-bottom {
    padding-bottom: 8.125rem !important;
}

.space-4 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
}

.space-4-top {
    padding-top: 12.5rem !important;
}

.space-4-bottom {
    padding-bottom: 12.5rem !important;
}

.space-5 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
}

.space-5-top {
    padding-top: 15rem !important;
}

.space-5-bottom {
    padding-bottom: 15rem !important;
}

.space-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.space-0-top {
    padding-top: 0 !important;
}

.space-0-bottom {
    padding-bottom: 0 !important;
}

@media (min-width: 576px) {
    .space-3--sm {
        padding-top: 8.125rem !important;
        padding-bottom: 8.125rem !important;
    }
    .space-5-top--sm {
        padding-top: 15rem !important;
    }
}

@media (min-width: 768px) {
    .space-1-top--md {
        padding-top: 2rem;
    }
    .space-2-top--md {
        padding-top: 4.0625rem;
    }
    .space-5-top--md {
        padding-top: 15rem;
    }
}

@media (min-width: 992px) {
    .space-3--lg {
        padding-top: 8.125rem;
        padding-bottom: 8.125rem;
    }
    .space-3-top--lg {
        padding-top: 8.125rem;
    }
    .space-4--lg {
        padding-top: 12.5rem;
        padding-bottom: 12.5rem;
    }
    .space-4-top--lg {
        padding-top: 12.5rem;
    }
    .space-5-top--lg {
        padding-top: 15rem;
    }
    .space-5-bottom--lg {
        padding-bottom: 15rem;
    }
    .space-0--lg {
        padding-top: 0;
        padding-bottom: 0;
    }
    .space-bottom-0--lg {
        padding-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .space-4-top--xl {
        padding-top: 12.5rem;
    }
    .space-5-top--xl {
        padding-top: 15rem;
    }
}

/*------------------------------------
  Divider
------------------------------------*/

.divider {
    position: relative;
    display: inline-block;
}

.divider::before,
.divider::after {
    position: absolute;
    top: 50%;
    content: '';
}

.divider::before {
    right: 100%;
    background-image: linear-gradient(45deg, transparent 0%, #bfc3ca 100%);
    background-repeat: repeat-x;
}

.divider::after {
    left: 100%;
    background-image: linear-gradient(45deg, #bfc3ca 0%, transparent 100%);
    background-repeat: repeat-x;
}

.divider--xs {
    font-size: 0.75rem;
}

.divider--xs::before,
.divider--xs::after {
    width: 2rem;
    height: 0.0625rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.divider--xs::before {
    margin-right: 0.625rem;
}

.divider--xs::after {
    margin-left: 0.625rem;
}

.divider--xs::before,
.divider--xs::after {
    width: 2rem;
}

/*------------------------------------
  Square
------------------------------------*/

.square {
    position: relative;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        position: absolute;
    }

    &:after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

/*------------------------------------
  Transition
------------------------------------*/

.transition {
    transition: all 0.3s ease;
}
