@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/keyframes', 'abstracts/mixins';

// 2. Vendors
@import '~antd/dist/antd.css', 'vendors/antd-custom', 'vendors/bootstrap-custom',
    'vendors/materialdesignicons-custom', 'vendors/react-select-custom', 'vendors/slick-custom',
    '~bootstrap/scss/bootstrap', '~cookieconsent/build/cookieconsent.min.css',
    '~@mdi/font/css/materialdesignicons.min.css';

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/alert', 'layout/header', 'layout/footer', 'layout/forms', 'layout/sidebar';

// 5. Components
@import 'components/button', 'components/drop-wrapper', 'components/input-password',
    'components/loader', 'components/placeholder', 'components/preview', 'components/progress',
    'components/search', 'components/select', 'components/tree', 'components/visor';

// 6. Page-specific styles
@import 'pages/home', 'pages/directories', 'pages/terms', 'pages/not-found';

// 7. Themes
@import 'themes/default';
