.eye {
    font-size: 1.5rem;
    padding: 0 0.5rem 0 0;
    position: absolute;
    right: 0;
    bottom: 0;

    &[data-type='text'] {
        .mdi-eye {
            display: block !important;
        }

        .mdi-eye-off {
            display: none;
        }
    }
}
