// -----------------------------------------------------------------------------
// This file contains all styles related to the preview component.
// -----------------------------------------------------------------------------

.preview-modal {
    height: 100vh;
    margin: auto;
    max-width: 100vw;
    width: 100vw;
    .powered {
        bottom: 100px;
        margin: 0.5rem 1rem;
        position: fixed;
        z-index: 9;
        small {
            color: rgba($gray-600, 0.3);
            font-size: 10px;
        }
        img {
            display: block;
            filter: brightness(0) opacity(0.2);
            width: 80px;
        }
    }
    .modal-content {
        border: none;
        border-radius: 0;
        height: 100vh;
        margin: auto;
        width: 100vw;
        .modal-header {
            align-items: center;
            height: 56px;
            .close {
                color: $primary;
                margin: -1rem 0 -1rem -1rem;
                opacity: 1;
                order: 1;
                &:before {
                    content: '\F004D';
                    font: normal normal normal 24px/1 'Material Design Icons';
                }
                &:hover {
                    color: darken($primary, 20%);
                }

                span {
                    display: none;
                }
            }
            .modal-title {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 0 auto 0 0;
                order: 2;
                width: 100%;
                white-space: nowrap;

                > span {
                    flex-grow: 1;
                    margin-right: 0.5rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 0;
                    white-space: nowrap;
                }
                #ActionsHeader {
                    margin: 0 -0.8rem 0 0;
                    position: relative;

                    i {
                        color: $primary;
                    }
                }
            }
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            padding: 0;
        }
    }
}
.preview {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-height: 120px;
    width: 100%;
    .preview-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        position: relative;
        width: 100%;

        &[data-type='text'] {
            height: 0;
            padding: 0;
        }

        textarea,
        [contentEditable] {
            border: none;
            font-family: monospace, Consolas, sans-serif !important;
            min-height: 100%;
            min-width: 100%;
            outline: none;
            white-space: pre;
            resize: none;
            padding: 1rem;
        }
    }
    .preview-footer {
        border-top: 1px solid $gray-300;
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        width: 100%;

        > div {
            display: flex;
            flex-direction: column;
            margin: 0 1rem;
        }
    }
    .img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        margin: 1rem;
        width: calc(100% - 2rem);
    }
    audio,
    video {
        align-items: center;
        display: flex;
        max-height: 100%;
        outline: none;
        position: absolute;
        width: calc(100% - 6rem);
    }
    audio {
        max-width: 500px;
    }
    video {
        padding: 1rem;
    }
    iframe {
        border: none;
        height: 100%;
        width: 100%;
    }
    .btn {
        align-items: center;
        color: rgba($gray-500, 0.3);
        display: flex;
        font-size: 3rem;
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        &:hover {
            color: darken($gray-500, 20%);
        }
        &.btn-prev {
            left: 0;
            width: 20%;
        }
        &.btn-next {
            justify-content: flex-end;
            right: 0;
            width: 80%;
        }
    }
}
