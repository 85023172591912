/**
 * Border
 */
.rounded-bottom {
    border-radius: 0 0 0.25rem 0.25rem !important;
}

/**
 * Box shadow
 */

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

/**
 * Form
 */
.form-group {
    position: relative;
}

/**
 * Buttons
 */
button:focus {
    outline: none !important;
}

.btn-group {
    [type='radio'] {
        position: absolute;
        margin: 0;
        opacity: 0;
        z-index: -1;
    }
}

.btn-link {
    text-decoration: none !important;
}

/**
 * Disabled
 */

[disabled],
.disabled {
    opacity: 0.65;
    cursor: default !important;
}

/**
 * Dropdown
 */

.dropdown {
    .dropdown-header {
        font-size: 0.8rem;
        color: $gray-400;
        text-transform: uppercase;
    }
}

/**
 * Tables
 */

thead {
    th {
        color: #c1c3c6;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.table-striped {
    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: #f8f9fa !important;
            }
        }
    }
}

table {
    span {
        + .icon {
            position: absolute;
            margin-top: -3px;
        }
    }
}

/**
 * Forms
 */
select {
    &.rounded-left {
        border-radius: 0.25rem 0 0 0.25rem !important;
    }
}

/**
 * Line height
 */
.line-height-0 {
    line-height: 0rem !important;
}
.line-height-1 {
    line-height: 1rem !important;
}

/**
 * Opacity
 */
.opacity-1 {
    opacity: 1 !important;
}

/**
 * Cursor
 */
.cursor-default {
    cursor: default !important;
}

/**
 * Modals
 */
.modal {
    .modal-header {
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        .modal-title {
            flex-grow: 1;

            img {
                width: 120px;
            }
        }
        .close {
            text-shadow: none;
            > span {
                display: flex;
                height: 24px;
                visibility: hidden;
                width: 24px;
                &:before {
                    content: '\F0156';
                    font: normal normal normal 24px/1 'Material Design Icons';
                    visibility: visible;
                }
            }
        }
    }

    .modal-footer {
        border: none;

        .btn {
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    &.fade {
        &.show .modal-dialog {
            &.modal-bottom {
                transform: translate(0, 0);
            }
        }

        .modal-dialog {
            &.modal-bottom {
                bottom: 0;
                position: fixed;
                transform: translate(0, 25%);
            }

            &.modal-bottom,
            &.modal-top {
                width: 100%;
                max-width: 100%;
                margin: 0;

                .modal-content {
                    border: none;
                    border-radius: 0;

                    .modal-header,
                    .modal-footer {
                        padding: 1rem 2rem;
                    }

                    .modal-body {
                        padding: 2rem 0;
                    }
                }
            }
        }
    }
}

/**
 * Cutom input: checkbox, radio
 */
.custom-control {
    .custom-radio {
        > input {
            top: 50% !important;
            margin-top: -0.75rem;
            margin-left: -1.4rem;
        }
    }

    .custom-control-label {
        &:before,
        &:after {
            top: 50% !important;
            margin-top: -8px;
        }
        &:empty {
            &:before,
            &:after {
                left: 0 !important;
                margin-left: 0 !important;
            }
        }
    }

    .custom-control-input {
        margin-left: 0;
        z-index: 1;
    }

    &[data-some-checked='true'] {
        .ant-checkbox {
            z-index: 0;
        }
    }
}
