// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

@media (min-width: 576px) {
    header {
        height: 70px;

        .navbar-nav {
            width: 100%;
            justify-content: flex-end;

            .nav-item {
                display: flex;
                align-items: center;
            }

            .dropdown {
                width: 100%;
                justify-content: flex-end;

                .nav-link {
                    &[aria-expanded='true'] {
                        img {
                            box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.3);
                        }
                    }
                }
            }
        }
    }
}
