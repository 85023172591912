// -----------------------------------------------------------------------------
// This file contains styles that are specific to the terms page.
// -----------------------------------------------------------------------------

.terms {
    .container {
        ol,
        ul {
            padding-left: 1rem;
            color: rgb(119, 131, 143);

            li {
                margin-bottom: 1rem;
            }

            h6 {
                font-weight: bold;
            }
        }

        & > div:last-child {
            hr {
                display: none;
            }
        }
    }
}

@media (min-width: 992px) {
    .terms {
        .container {
            max-width: 750px;
        }
    }
}

@media screen and (min-width: 67.5em) {
    .terms {
        .container {
            max-width: 990px;
        }
    }
}
