.sidebar-wrapper {
    .btn {
        box-shadow: none !important;
        &.icon {
            padding: 0;
            width: 40px;
            height: 40px;
            text-align: center;
        }

        i {
            font-size: 1.5rem;
        }
    }

    .sidebar-toggler {
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
        z-index: 10;
        &.position-fixed {
            top: 1000%;
        }
        &:after {
            content: '';
            position: fixed;
            top: 1000%;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.35);
            opacity: 0;
            transition: top 0s ease 0.35s, opacity 0.35s ease;
        }
    }

    .navbar-collapse {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        transition: transform 0.3s ease;
        z-index: 11;
        transform: translate(-100vw);

        &.show {
            transform: translate(0);
            & ~ .sidebar-toggler {
                cursor: default;
                &:after {
                    top: 0;
                    opacity: 1;
                    transition: top 0s ease 0s, opacity 0.35s ease;
                }
            }
        }
    }

    .sidebar {
        &.sidebar-main {
            width: 64px;
            z-index: 3;
        }
        &.sidebar-menu {
            width: 240px;
            z-index: 2;

            .btn {
                text-align: left;
                margin: 0 0 0.2rem;
                padding: 0 0.5rem;
                display: flex;
                align-items: center;
                white-space: normal;
                font-size: 0.9rem;
                line-height: 34px;

                i {
                    font-size: 1.2rem;
                    margin-right: 1rem;
                }
                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &.sidebar-creation,
        &.sidebar-search {
            width: 100vw;
            max-width: 600px;

            .navbar-brand {
                align-self: flex-start;
                width: 64px;
                text-align: center;
            }
        }
        .nav {
            height: 100vh;

            .navbar-brand {
                margin: 0;
            }
            .dropdown {
                &.apps {
                    .dropdown-toggle {
                        padding: 0 0.5rem;
                        position: relative;
                        &:after {
                            position: absolute;
                            bottom: 0;
                        }
                        img {
                            box-shadow: none !important;
                        }
                    }
                    .dropdown-menu {
                        padding: 0.5rem;
                        font-size: 0.8rem;
                        > div {
                            display: flex;
                        }
                        .dropdown-header {
                            width: 100%;
                        }
                        .dropdown-item {
                            align-items: center;
                            border-radius: 0.25rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 0.25rem;
                            width: 100px;
                            &:active {
                                background-color: $gray-100;
                                color: $gray-900;
                            }

                            img {
                                margin: 0.25rem auto;
                            }
                        }
                    }
                }
                .dropdown-menu {
                    font-size: 0.9rem;
                }
                .nav-link {
                    &[aria-expanded='true'] {
                        img {
                            box-shadow: 0 0 0 2px #fff, 0 0 0 0.3rem $primary;
                        }
                    }
                }
            }
        }
    }

    & ~ .container-wrapper {
        padding: 1rem;
    }
}

@media (min-width: 768px) {
    .sidebar-wrapper {
        [toggler='#SidebarToggler'] {
            transform: translate(0);
        }
        & ~ .container-wrapper {
            margin-left: 64px;
        }
        &.sidebar-wide {
            & ~ .container-wrapper {
                margin-left: 304px;
            }
        }
    }
}
