.slick-slider {
    &.quotes {
        h5 {
            line-height: 1.5;
        }

        h6 {
            opacity: 0.6;
        }

        .slick-dots {
            &.avatars {
                position: relative;
                display: flex !important;
                justify-content: space-evenly;
                padding: 2rem 4rem 0;
                list-style: none;
                margin: 0;

                li {
                    width: 32px;
                    height: 32px;
                    opacity: 0.6;
                    transition: transform 0.3s ease;

                    img {
                        border-radius: 10rem;
                        width: 100%;
                    }

                    &:hover,
                    &.slick-active {
                        opacity: 1;
                        transform: scale(1.3);
                    }
                }
            }
        }
    }
}
